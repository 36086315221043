import Dashboard from "../Pages/Dashboard/Dashboard";
import BranchList from "../Pages/BranchList/BranchList";
import BranchDetail from "../Pages/BranchDetail/BranchDetail";
import FourHundred from "../Pages/FourHundred/FourHundred";
import ResetPassword from "../Pages/ModifyPassword/ResetPassword/ResetPassword";
import ForgetPassword from "../Pages/ForgetPassword/ForgetPassword";
import UserList from "../Pages/UserList/UserList";
import CompanyList from "../Pages/CompanyList/CompanyList";
import CompanyDetail from "../Pages/CompanyDetail/CompanyDetail";
import Login from "../Pages/Login/Login";
import Unauthorized from "../Pages/Unauthorized/Unauthorized";
import UserDetail from "../Pages/UserDetail/UserDetail";
import CreatePassword from "../Pages/ModifyPassword/CreatePassword/CreatePassword";
import GroupCompanyList from "../Pages/GroupCompanyList/GroupCompanyList";
import OfList from "../Pages/OfList/OfList";

import { IRoute } from "../interfaces/routeInterface";
import ErrorNotCatch from "../Pages/ErrorNotCatch/ErrorNotCatch";
import CompetenceList from "../Pages/CompetenceList/CompetenceList";
import CompetenceDetail from "../Pages/CompetenceDetail/CompetenceDetail";
import CompetenceBlockList from "../Pages/CompetenceBlockList/CompetenceBlockList";
import CompetenceBlockDetail from "../Pages/CompetenceBlockDetail/CompetenceBlockDetail";
import CertificationList from "../Pages/CertificationList/CertificationList";
import CertificationDetail from "../Pages/CertificationDetail/CertificationDetail";
import SignUp from "../Pages/SignUp/SignUp";
import AccountInformation from "../Pages/AccountInformations";

import {
  AiFillHome,
  AiFillTool,
  AiFillSafetyCertificate,
} from "react-icons/ai";
import { MdGroups, MdApartment } from "react-icons/md";
import { BsShareFill, BsGearFill, BsBank2 } from "react-icons/bs";
import {
  FaBuilding,
  FaCertificate,
  FaGraduationCap,
  FaToolbox,
  FaWarehouse,
  FaFileAlt,
  FaFileExport,
  FaUser,
  FaCity,
} from "react-icons/fa";
import { MdManageAccounts } from "react-icons/md";
import { MdPersonalInjury } from "react-icons/md";
import ContactAdmin from "../Pages/ContactAdmin";
import SelectBranch from "../Pages/Login/SelectBranch";
import OfDetail from "../Pages/OfDetail/OfDetail";
import FicheCandidat from "../Pages/FicheCandidat";
import ContactAdminPublic from "../Pages/ContactAdminPublic";
import RolesManagement from "../Pages/RolesManagement/RolesManagement";
import GroupCompanyDetail from "../Pages/GroupCompanyDetail/GroupCompanyDetail";
import GroupOfList from "../Pages/GroupOfList/GroupOfList";
import GroupOfDetail from "../Pages/GroupOfDetail/GroupOfDetail";
import ProfilCandidatDetail from "../Pages/ProfilCandidatDetail/ProfilCandidatDetail";
import CandidatureWorkflow from "../Pages/CandidatureWorkflow/CandidatureWorkflow";
import JuryList from "../Pages/JuryList";
import CandidatureList from "../Pages/CandidatureList/CandidatureList";
import StaticPageDetail from "../Pages/StaticPageDetail/StaticPageDetail";
import StaticPageList from "../Pages/StaticPageList/StaticPageList";
import GeneralPagePublic from "../Pages/GeneralPagePublic/GeneralPagePublic";
import GeneralPagePrivate from "../Pages/GeneralPagePrivate/GeneralPagePrivate";
import BranchSettingsDetail from "../Pages/BranchSettingsDetail/BranchSettingsDetail";
import SubGroupOfList from "../Pages/SubGroupOfList/SubGroupOfList";
import SubGroupCompanyList from "../Pages/SubGroupCompanyList/SubGroupCompanyList";
import { branchAccessEnum, userAccessEnum } from "./accessEnum";
import LabelSettingsDetail from "../Pages/LabelSettingsDetail/LabelSettingsDetail";
import JuryDetail from "../Pages/JuryDetail";
import CandidatJuryList from "../Pages/CandidatJuryList/CandidatJuryList";
import SubGroupCompanyDetail from "../Pages/SubGroupCompanyDetail/SubGroupCompanyDetail";
import SubGroupOfDetail from "../Pages/SubGroupOfDetail/SubGroupOfDetail";
import DynamicFields from "../Pages/DynamicFields/DynamicFields";
import ValidationCandidatureList from "../Pages/ValidationCandidatureList/ValidationCandidatureList";
//import FormulaireSuiviCohortes from "../Pages/SuiviCohortes/FormulaireSuiviCohortes/FormulaireSuiviCohortes";
import PageSuiviCandidat from "../Pages/SuiviCohortes/PageSuiviCandidat/PageSuiviCandidat";
import DomaineList from "../Pages/DomaineList/DomaineList";
import DomaineDetail from "../Pages/DomaineDetail/DomaineDetail";
import OfflineCandidatureList from "../Pages/OfflineCandidatureList/OfflineCandidatureList";
import OfflineCandidatureSyncList from "../Pages/OfflineCandidatureSyncList/OfflineCandidatureSyncList";
import SuiviCohortesList from "../Pages/SuiviCohortesList/SuiviCohortesList";
import AsyncFileDownload from "../Pages/AsyncFileDownload/AsyncFileDownload";
import ExportCaisseDepotPage from "../Pages/ExportCaisseDepotPage/ExportCaisseDepotPage";
import DemandeInscriptionList from "../Pages/DemandeInscriptionList/DemandeInscriptionList";
import PlatformUserList from "../Pages/PlatformUserList/PlateformUserList";
import HabilitationOfList from "../Pages/HabilitationOfList/HabilitationOfList";
import HabilitationOfDetail from "../Pages/HabilitationOfDetail/HabilitationOfDetail";
import HabilitationUtilisateurList from "../Pages/HabilitationUtilisateurList/HabilitationUtilisateurList";
import HabilitationUtilisateurDetail from "../Pages/HabilitationUtilisateurDetail/HabilitationUtilisateurDetail";
import DemandeHabilitationOfList from "../Pages/DemandeHabilitationOfList/DemandeHabilitationOfList";
import DemandeHabilitationUtilisateurList from "../Pages/DemandeHabilitationUtilisateurList/DemandeHabilitationUtilisateurList";
import DemandeInscriptionStructureList from "../Pages/DemandeInscriptionStructureList/DemandeInscriptionStructureList";
import MyStructureDetail from "../Pages/MyStructureDetail/MyStructureDetail";

const NAV_ICON_SIZE = 20;

/**
 * Define home page of profiles for redirection after login
 */

export const homePageByProfile: any = {
  ADMINISTRATEUR_BRANCHE: "/admin/accueil",
  ENTREPRISE: "/admin/accueil",
  GROUPE_ENTREPRISE: "/admin/accueil",
  SOUS_GROUPE_ENTREPRISE: "/admin/accueil",
  OF: "/admin/accueil",
  GROUPE_OF: "/admin/accueil",
  SOUS_GROUPE_OF: "/admin/accueil",
  ADMINISTRATEUR_DOMAINE: "/admin/accueil",
  POSITIONNEUR: "/admin/accueil",
  EVALUATEUR: "/admin/accueil",
  CANDIDAT: "/admin/fiche-candidat",
  MEMBRE_JURY: "/admin/accueil",
  CONSULTATION: "/admin/accueil",
  CONSULTATION_OF: "/admin/accueil",
  SECOND_EVALUATEUR: "/admin/candidature",
};

export const routeGroups: any = {
  BACKOFFICE: {
    id: "BACKOFFICE",
    name: "Paramétrage de ma branche",
    icon: <BsGearFill size={NAV_ICON_SIZE} />,
    startOpen: false,
    allowedAccess: [
      userAccessEnum.NO_ACCESS,
      userAccessEnum.PARAMETRER_PAGES_STATIQUES,
      userAccessEnum.PARAMETRER_MATRICE_ACL,
      userAccessEnum.PARAMETRER_BRANCHE,
      userAccessEnum.PARAMETRER_LIBELLES,
      userAccessEnum.PARAMETRER_CHAMPS_COMPLEMENTAIRES,
    ],
    visibleOffline: false,
  },

  BRANCH: {
    id: "BRANCH",
    name: "Gestion de ma branche",
    icon: <BsGearFill size={NAV_ICON_SIZE} />,
    startOpen: false,
    allowedAccess: [
      userAccessEnum.GERER_UTILISATEUR,
      userAccessEnum.PARAMETRER_ENTREPRISES_OF_GROUPES,
      userAccessEnum.VISUALISER_SOUS_GROUPE,
      userAccessEnum.PARAMETRER_COMPETENCES,
      userAccessEnum.PARAMETRER_BLOCS_COMPETENCES,
      userAccessEnum.PARAMETRER_CERTIFICATIONS,
      userAccessEnum.PARAMETRER_CERTIFICATIONS,
    ],
    visibleOffline: false,
  },
  ADMINISTRATION: {
    id: "ADMINISTRATION",
    name: "Administration générale de la plateforme",
    icon: <BsGearFill size={NAV_ICON_SIZE} />,
    startOpen: false,
    allowedAccess: [userAccessEnum.NO_ACCESS],
  },
};

const privatePrimaryRoutes = [
  {
    type: "private",
    path: "/",
    title: "Accueil",
    inNavbar: false,
    element: <Dashboard />,
    icon: <AiFillHome size={NAV_ICON_SIZE} />,
    allowedAccess: [userAccessEnum.VISUALISER_ACCUEIL_APRES_CONNEXION],
  },
  {
    type: "private",
    path: "/admin/accueil",
    title: "Accueil",
    inNavbar: true,
    element: <Dashboard />,
    icon: <AiFillHome size={NAV_ICON_SIZE} />,
    allowedAccess: [userAccessEnum.VISUALISER_ACCUEIL_APRES_CONNEXION],
  },
  {
    type: "private",
    path: "/admin/fiche-candidat",
    title: "Ma fiche individuelle",
    inNavbar: true,
    element: <FicheCandidat />,
    icon: <AiFillHome size={NAV_ICON_SIZE} />,
    allowedAccess: [userAccessEnum.VISUALISATION_FICHE_CANDIDAT],
    allowedProfiles: ["CANDIDAT"],
  },
  {
    type: "private",
    path: "/admin/media/:mediaUuid",
    title: "Téléchargement asynchrone",
    inNavbar: false,
    element: <AsyncFileDownload />,
    icon: <AiFillHome size={NAV_ICON_SIZE} />,
    allowedAccess: ["*"],
  },
  {
    type: "private",
    path: "/admin/candidature", //ADD /:UUID
    title: "Candidatures",
    inNavbar: true,
    element: <CandidatureList />,
    icon: <FaFileAlt size={NAV_ICON_SIZE} />,
    allowedAccess: [userAccessEnum.VISUALISER_TABLEAU_BORD_CANDIDATURES],
  },
  {
    type: "private",
    path: "/admin/offline/candidature", //ADD /:UUID
    title: "Évaluation hors ligne",
    inNavbar: true,
    element: <OfflineCandidatureList />,
    icon: <FaFileAlt size={NAV_ICON_SIZE} />,
    allowedAccess: [
      userAccessEnum.SAISIR_EVALUATION,
      userAccessEnum.SAISIR_AVIS_SECOND_EVALUATEUR,
    ],
  },
  {
    type: "private",
    path: "/admin/offline/candidature-sync", //ADD /:UUID
    title: "Évaluation hors ligne",
    inNavbar: false,
    element: <OfflineCandidatureSyncList />,
    icon: <FaFileAlt size={NAV_ICON_SIZE} />,
    allowedAccess: [
      userAccessEnum.SAISIR_EVALUATION,
      userAccessEnum.SAISIR_AVIS_SECOND_EVALUATEUR,
    ],
    visibleOffline: true,
  },
  {
    type: "private",
    path: "/admin/valider-candidature", //ADD /:UUID
    title: "Inscriptions jury à valider",
    inNavbar: true,
    element: <ValidationCandidatureList />,
    icon: <FaFileExport size={NAV_ICON_SIZE} />,
    allowedAccess: [
      userAccessEnum.NO_ACCESS,
      userAccessEnum.VALIDER_INSCRIPTIONS_JURY,
    ],
  },
  {
    type: "private",
    path: "/admin/jurys",
    title: "Jurys",
    inNavbar: true,
    element: <JuryList />,
    icon: <MdPersonalInjury size={NAV_ICON_SIZE} />,
    allowedAccess: [userAccessEnum.VISUALISER_JURYS],
  },
  {
    type: "private",
    path: "/admin/export-caisse",
    title: "Export Caisse des dépôts",
    inNavbar: true,
    element: <ExportCaisseDepotPage />,
    icon: <BsBank2 size={NAV_ICON_SIZE} />,
    allowedAccess: [userAccessEnum.EXPORTER_XML],
  },
  {
    type: "private",
    path: "/admin/suivi-cohortes",
    title: "Suivi des cohortes",
    inNavbar: true,
    element: <SuiviCohortesList />,
    icon: <FaGraduationCap size={NAV_ICON_SIZE} />,
    allowedAccess: [userAccessEnum.VISUALISER_TITULAIRES_A_SUIVRE],
    showSuiviCohorte: true,
  },
  {
    type: "private",
    path: "/admin/demandes-inscription-utilisateurs",
    title: "Demande d'inscription utilisateurs",
    inNavbar: true,
    element: <DemandeInscriptionList />,
    icon: <FaUser size={NAV_ICON_SIZE} />,
    allowedAccess: [userAccessEnum.VISUALISER_DEMANDE_INSCRIPTION_UTILISATEUR],
    showIsReferentUserOnly: true,
  },
  {
    type: "private",
    path: "/admin/ma_structure/:type/:uuid",
    title: "Structure",
    inNavbar: false,
    element: <MyStructureDetail />,
    icon: <FaUser size={NAV_ICON_SIZE} />,
    allowedAccess: [userAccessEnum.MODIFIER_INFORMATION_MA_STRUCTURE],
    showIsReferentUserOnly: true,
  },
  {
    type: "private",
    path: "/admin/demandes-inscription-structures",
    title: "Demande d'inscription structures",
    inNavbar: true,
    element: <DemandeInscriptionStructureList />,
    icon: <FaCity size={NAV_ICON_SIZE} />,
    allowedAccess: [userAccessEnum.VISUALISER_DEMANDE_INSCRIPTION_UTILISATEUR],
    //allowedAccess: [userAccessEnum.VISUALISER_DEMANDE_INSCRIPTION_STRUCTURE],
    showIsReferentUserOnly: true,
  },
  {
    type: "private",
    path: "/admin/parametres-branche",
    title: "Paramètres de la branche",
    inNavbar: true,
    element: <BranchSettingsDetail />,
    allowedAccess: [userAccessEnum.PARAMETRER_BRANCHE],
    icon: <MdManageAccounts size={NAV_ICON_SIZE} />,
    routeGroup: routeGroups.BACKOFFICE,
  },
  {
    type: "private",
    path: "/admin/gestionmultiroles",
    title: "Gestion des multi-rôles",
    inNavbar: true,
    element: <RolesManagement />,
    allowedAccess: [userAccessEnum.PARAMETRER_MATRICE_ACL],
    icon: <MdManageAccounts size={NAV_ICON_SIZE} />,
    routeGroup: routeGroups.BACKOFFICE,
  },
  {
    type: "private",
    path: "/admin/pages/branche",
    title: "Pages de la branche",
    inNavbar: true,
    element: <StaticPageList type="branche" />,
    icon: <BsGearFill size={NAV_ICON_SIZE} />,
    allowedAccess: [userAccessEnum.PARAMETRER_PAGES_STATIQUES],
    routeGroup: routeGroups.BACKOFFICE,
  },
  {
    type: "private",
    path: "/admin/parametrage-labels",
    title: "Paramètrage des libellés",
    inNavbar: true,
    element: <LabelSettingsDetail />,
    allowedAccess: [userAccessEnum.PARAMETRER_LIBELLES],
    icon: <MdManageAccounts size={NAV_ICON_SIZE} />,
    routeGroup: routeGroups.BACKOFFICE,
  },
  {
    type: "private",
    path: "/admin/parametrage-champs-complementaires",
    title: "Champs complémentaires",
    inNavbar: true,
    element: <DynamicFields />,
    allowedAccess: [userAccessEnum.PARAMETRER_CHAMPS_COMPLEMENTAIRES],
    icon: <MdManageAccounts size={NAV_ICON_SIZE} />,
    routeGroup: routeGroups.BACKOFFICE,
  },
  {
    type: "private",
    path: "/admin/domaines",
    title: "Domaines",
    inNavbar: true,
    element: <DomaineList />,
    icon: <BsShareFill size={NAV_ICON_SIZE} />,
    allowedProfiles: ["ADMINISTRATEUR_BRANCHE"],
    allowedAccess: ["*"],
    routeGroup: routeGroups.BRANCH,
    showWithDomainSystem: true,
  },

  {
    type: "private",
    path: "/admin/utilisateurs",
    title: "Utilisateurs",
    inNavbar: true,
    element: <UserList />,
    icon: <MdGroups size={NAV_ICON_SIZE} />,
    allowedAccess: [userAccessEnum.GERER_UTILISATEUR],
    routeGroup: routeGroups.BRANCH,
  },
  {
    type: "private",
    path: "/admin/entreprises",
    title: "Entreprises",
    inNavbar: true,
    element: <CompanyList />,
    icon: <FaBuilding size={NAV_ICON_SIZE} />,
    allowedAccess: [userAccessEnum.PARAMETRER_ENTREPRISES_OF_GROUPES],
    routeGroup: routeGroups.BRANCH,
  },
  {
    type: "private",
    path: "/admin/sous-groupes-entreprises",
    title: "Sous-groupes d'entreprises",
    inNavbar: true,
    element: <SubGroupCompanyList />,
    icon: <MdApartment size={NAV_ICON_SIZE} />,
    allowedAccess: [userAccessEnum.VISUALISER_SOUS_GROUPE],
    routeGroup: routeGroups.BRANCH,
  },
  {
    type: "private",
    path: "/admin/groupes-entreprises",
    title: "Groupes d'Entreprises",
    inNavbar: true,
    element: <GroupCompanyList />,
    icon: <MdApartment size={NAV_ICON_SIZE} />,
    allowedAccess: [userAccessEnum.PARAMETRER_ENTREPRISES_OF_GROUPES],
    routeGroup: routeGroups.BRANCH,
  },

  {
    type: "private",
    path: "/admin/of",
    title: "Organismes de formation",
    inNavbar: true,
    element: <OfList />,
    icon: <FaCertificate size={NAV_ICON_SIZE} />,
    allowedAccess: [userAccessEnum.PARAMETRER_ENTREPRISES_OF_GROUPES],
    routeGroup: routeGroups.BRANCH,
  },
  {
    type: "private",
    path: "/admin/sous-groupes-ofs",
    title: "Sous-groupes d'organismes de formation",
    inNavbar: true,
    element: <SubGroupOfList />,
    icon: <FaWarehouse size={NAV_ICON_SIZE} />,
    allowedAccess: [userAccessEnum.VISUALISER_SOUS_GROUPE],
    routeGroup: routeGroups.BRANCH,
  },
  {
    type: "private",
    path: "/admin/groupe-of",
    title: "Groupes d'organismes de formation",
    inNavbar: true,
    element: <GroupOfList />,
    icon: <FaWarehouse size={NAV_ICON_SIZE} />,
    allowedAccess: [userAccessEnum.PARAMETRER_ENTREPRISES_OF_GROUPES],
    routeGroup: routeGroups.BRANCH,
  },
  {
    type: "private",
    path: "/admin/habilitations-of",
    title: "Habilitations des OF",
    inNavbar: true,
    element: <HabilitationOfList />,
    icon: <AiFillSafetyCertificate size={NAV_ICON_SIZE} />,
    allowedAccess: [userAccessEnum.PARAMETRER_CERTIFICATIONS],
    routeGroup: routeGroups.BRANCH,
    habilitationAccess: [branchAccessEnum.HABILITATION_SIMPLE_OF],
  },
  {
    type: "private",
    path: "/admin/demandes-habilitation-of",
    title: "Demande d'habilitations",
    inNavbar: true,
    element: <DemandeHabilitationOfList />,
    icon: <AiFillSafetyCertificate size={NAV_ICON_SIZE} />,
    allowedAccess: [userAccessEnum.PARAMETRER_CERTIFICATIONS],
    routeGroup: routeGroups.BRANCH,
    habilitationAccess: [branchAccessEnum.HABILITATION_SIMPLE_OF],
  },
  {
    type: "private",
    path: "/admin/habilitations-utilisateur",
    title: "Habilitations des utilisateurs",
    inNavbar: true,
    element: <HabilitationUtilisateurList />,
    icon: <AiFillSafetyCertificate size={NAV_ICON_SIZE} />,
    allowedAccess: [userAccessEnum.PARAMETRER_CERTIFICATIONS],
    routeGroup: routeGroups.BRANCH,
    habilitationAccess: [
      branchAccessEnum.HABILITATION_STRICTE_EVALUATEUR,
      branchAccessEnum.HABILITATION_STRICTE_POSITIONNEUR,
    ],
  },
  {
    type: "private",
    path: "/admin/demandes-habilitation-utilisateur",
    title: "Demande d'habilitations",
    inNavbar: true,
    element: <DemandeHabilitationUtilisateurList />,
    icon: <AiFillSafetyCertificate size={NAV_ICON_SIZE} />,
    allowedAccess: [userAccessEnum.PARAMETRER_CERTIFICATIONS],
    routeGroup: routeGroups.BRANCH,
    habilitationAccess: [
      branchAccessEnum.HABILITATION_STRICTE_EVALUATEUR,
      branchAccessEnum.HABILITATION_STRICTE_POSITIONNEUR,
    ],
  },
  {
    type: "private",
    path: "/admin/habilitations-of/creer",
    title: "Détail",
    inNavbar: false,
    element: <HabilitationOfDetail />,
    allowedAccess: [userAccessEnum.PARAMETRER_CERTIFICATIONS],
    routeGroup: routeGroups.BRANCH,
  },
  {
    type: "private",
    path: "/admin/habilitations-utilisateur/creer",
    title: "Détail",
    inNavbar: false,
    element: <HabilitationUtilisateurDetail />,
    allowedAccess: [userAccessEnum.PARAMETRER_CERTIFICATIONS],
    routeGroup: routeGroups.BRANCH,
  },
  {
    type: "private",
    path: "/admin/competences",
    title: "Compétences",
    inNavbar: true,
    element: <CompetenceList />,
    icon: <AiFillTool size={NAV_ICON_SIZE} />,
    allowedAccess: [userAccessEnum.PARAMETRER_COMPETENCES],
    routeGroup: routeGroups.BRANCH,
  },
  {
    type: "private",
    path: "/admin/blocs-de-competences",
    title: "Blocs de compétences",
    inNavbar: true,
    element: <CompetenceBlockList />,
    icon: <FaToolbox size={NAV_ICON_SIZE} />,
    allowedAccess: [userAccessEnum.PARAMETRER_BLOCS_COMPETENCES],
    routeGroup: routeGroups.BRANCH,
  },
  {
    type: "private",
    path: "/admin/certifications",
    title: "Certifications",
    inNavbar: true,
    element: <CertificationList />,
    icon: <FaGraduationCap size={NAV_ICON_SIZE} />,
    allowedAccess: [userAccessEnum.PARAMETRER_CERTIFICATIONS],
    routeGroup: routeGroups.BRANCH,
  },
  {
    type: "private",
    path: "/admin/branches",
    title: "Branches",
    inNavbar: true,
    element: <BranchList />,
    icon: <BsShareFill size={NAV_ICON_SIZE} />,
    allowedAccess: [userAccessEnum.NO_ACCESS],
    routeGroup: routeGroups.ADMINISTRATION,
  },
  {
    type: "private",
    path: "/admin/pages/generales",
    title: "Pages Générales",
    inNavbar: true,
    element: <StaticPageList type="generales" />,
    icon: <BsGearFill size={NAV_ICON_SIZE} />,
    allowedAccess: [userAccessEnum.PARAMETRER_PAGES_STATIQUES],
    routeGroup: routeGroups.ADMINISTRATION,
  },
  {
    type: "private",
    path: "/administrateurs-todo", //to implement
    title: "Administrateurs de la plateforme",
    inNavbar: false,
    element: <></>,
    icon: <BsGearFill size={NAV_ICON_SIZE} />,
    allowedAccess: [],
    routeGroup: routeGroups.ADMINISTRATION,
  },
  {
    type: "private",
    path: "/utilisateurs-plateforme", //to implement
    title: "Utilisateurs de la plateforme",
    inNavbar: true,
    element: <PlatformUserList />,
    icon: <BsGearFill size={NAV_ICON_SIZE} />,
    allowedAccess: [userAccessEnum.NO_ACCESS],
    routeGroup: routeGroups.ADMINISTRATION,
  },
];
const privateSecondaryRoutes = [
  {
    type: "private",
    path: "/admin/jurys/:uuid",
    title: "Détail",
    inNavbar: false,
    element: <JuryDetail />,
    allowedAccess: [userAccessEnum.CREER_JURYS_CERTIFICATION],
  },
  {
    type: "private",
    path: "/admin/jurys/:uuid_jury/candidats",
    title: "Visualiser",
    inNavbar: false,
    element: <CandidatJuryList />,
    allowedAccess: [userAccessEnum.VISUALISER_JURY],
  },
  {
    type: "private",
    path: "/admin/groupe-of/:uuid",
    title: "Détail",
    inNavbar: false,
    element: <GroupOfDetail />,
    allowedAccess: [userAccessEnum.PARAMETRER_ENTREPRISES_OF_GROUPES],
  },
  {
    type: "private",
    path: "/admin/pages/:type/:id",
    title: "Détail",
    inNavbar: false,
    element: <StaticPageDetail />,
    allowedAccess: [userAccessEnum.PARAMETRER_PAGES_STATIQUES],
  },
  {
    type: "private",
    path: "/admin/fiche-candidat/:uuid_candidat",
    title: "Fiche individuelle",
    inNavbar: false,
    element: <FicheCandidat />,
    allowedAccess: [userAccessEnum.VISUALISATION_FICHE_CANDIDAT],
  },

  {
    type: "private",
    path: "/admin/blocs-de-competences/:uuid",
    title: "Détail",
    inNavbar: false,
    element: <CompetenceBlockDetail />,
    allowedAccess: [userAccessEnum.PARAMETRER_BLOCS_COMPETENCES],
  },
  {
    //to DELETE AFTER
    type: "private",
    path: "/admin/pages",
    title: "Pages",
    inNavbar: false,
    element: <></>,
    allowedAccess: ["*"],
  },

  {
    type: "private",
    path: "/select-branch",
    title: "Branchs",
    inNavbar: false,
    element: <SelectBranch />,
    allowedAccess: ["*"],
  },

  {
    type: "private",
    path: "/admin/cgu",
    title: "CGU de la branche",
    inNavbar: false,
    element: <GeneralPagePrivate type="CGU" />,
    allowedAccess: ["*"],
  },
  {
    type: "private",
    path: "/admin/mentions_legales",
    title: "Mentions légales de la branche",
    inNavbar: false,
    element: <GeneralPagePrivate type="MENTIONS_LEGALES" />,
    allowedAccess: ["*"],
  },

  {
    type: "private",
    path: `/admin/branches/:id`,
    title: "Détail",
    inNavbar: false,
    element: <BranchDetail />,
    allowedAccess: [userAccessEnum.NO_ACCESS],
  },
  {
    type: "private",
    path: `/admin/mon_profile`,
    title: "informations du compte",
    inNavbar: false,
    element: <AccountInformation />,
    allowedAccess: ["*"],
  },
  {
    type: "private",
    path: `/admin/demande_aide`,
    title: "Demande d'aide",
    inNavbar: false,
    element: <ContactAdmin />,
    allowedAccess: [userAccessEnum.DEMANDER_AIDE_BRANCHE],
  },
  {
    type: "private",
    path: "/admin/groupes-entreprises/:uuid",
    title: "Détail",
    inNavbar: false,
    element: <GroupCompanyDetail />,
    allowedAccess: [userAccessEnum.PARAMETRER_ENTREPRISES_OF_GROUPES],
  },
  {
    type: "private",
    path: "/admin/domaines/:uuid",
    title: "Détail",
    inNavbar: false,
    element: <DomaineDetail />,
    allowedProfiles: ["ADMINISTRATEUR_BRANCHE"],
    allowedAccess: ["*"],
  },
  {
    type: "private",
    path: "/admin/utilisateurs/:uuid",
    title: "Détail",
    inNavbar: false,
    element: <UserDetail />,
    allowedAccess: [userAccessEnum.CREER_UTILISATEUR],
  },
  {
    type: "private",
    path: "/admin/sous-groupes-entreprises/:sous_groupe_entreprise_uuid",
    title: "Détail",
    inNavbar: false,
    element: <SubGroupCompanyDetail />,
    allowedAccess: [userAccessEnum.MODIFIER_SOUS_GROUPE],
  },
  {
    type: "private",
    path: "/admin/entreprises/:uuid",
    title: "Détail",
    inNavbar: false,
    element: <CompanyDetail />,
    allowedAccess: [userAccessEnum.PARAMETRER_ENTREPRISES_OF_GROUPES],
  },

  {
    type: "private",
    path: "/admin/of/:uuid",
    title: "Détail",
    inNavbar: false,
    element: <OfDetail />,
    allowedAccess: [userAccessEnum.PARAMETRER_ENTREPRISES_OF_GROUPES],
  },
  {
    type: "private",
    path: "/admin/sous-groupes-ofs/:sous_groupe_of_uuid",
    title: "Détail",
    inNavbar: false,
    element: <SubGroupOfDetail />,
    allowedAccess: [userAccessEnum.MODIFIER_SOUS_GROUPE],
  },
  {
    type: "private",
    path: "/admin/certifications/:uuid",
    title: "Détail",
    inNavbar: false,
    element: <CertificationDetail />,
    allowedAccess: [userAccessEnum.PARAMETRER_CERTIFICATIONS],
  },

  {
    //to DELETE AFTER
    type: "private",
    path: "/admin/pages",
    title: "Pages",
    inNavbar: false,
    element: <></>,
    allowedAccess: ["*"],
  },

  {
    //a modifie -> en fonction de l'url de la page de synthese d'un candidat
    //a modifie -> rajouter uuid
    type: "private",
    path: "/admin/fiche-candidat/:uuid_candidat/profil",
    title: "Profil",
    inNavbar: false,
    element: <ProfilCandidatDetail />,
    allowedAccess: ["*"],
  },
  {
    type: "private",
    //a modifie -> en fonction de l'url de la page de synthese d'un candidat
    //a modifie -> rajouter uuid
    path: "/admin/candidature/:id_candidature/:statut_candidature",
    title: "Candidature",
    inNavbar: false,
    element: <CandidatureWorkflow />,
    allowedAccess: [
      userAccessEnum.SAISIR_CANDIDATURE,
      userAccessEnum.SAISIR_1ER_INFO_CANDIDATURE,
      userAccessEnum.SAISIR_PROFIL_CANDIDAT,
      userAccessEnum.INVITER_CANDIDAT_CREATION_CANDIDATURE,
      userAccessEnum.SAISIR_PARCOURS_CANDIDAT,
      userAccessEnum.CHOIX_BLOCS_CERTIF,
      userAccessEnum.VISUALISER_CHOIX_BLOCS_CERTIF,
      userAccessEnum.MODIFIER_CHOIX_BLOCS_CERTIF,
      userAccessEnum.IDENTIFICATION_PARTIES_PRENANTES_PARCOURS,
      userAccessEnum.VISUALISER_IDENTIFICATION_PARTIES_PRENANTES_PARCOURS,
      userAccessEnum.TRANSMISSION_DOSSIER_CANDIDATURE,
      userAccessEnum.VISUALISER_CANDIDATURE,
      userAccessEnum.MODIFIER_INFO_CANDIDATURE_AVANT_CERTIF,
      userAccessEnum.MODIFIER_INFO_CANDIDATURE_APRES_CERTIF,
      userAccessEnum.MODIFIER_PARCOURS_CANDIDAT,
      userAccessEnum.SAISIR_POSITIONNEMENT,
      userAccessEnum.MODIFIER_POSITIONNEMENT_AVANT_EVALUATION_OU_AVIS,
      userAccessEnum.VISUALISER_POSITIONNEMENT,
      userAccessEnum.SAISIR_AVIS_CANDIDAT_POSITIONNEMENT,
      userAccessEnum.MODIFIER_AVIS_CANDIDAT_POSITIONNEMENT,
      userAccessEnum.VISUALISER_AVIS_CANDIDAT_POSITIONNEMENT,
      userAccessEnum.CREER_AVIS_FORMATEUR,
      userAccessEnum.MODIFIER_EVALUATION_APRES_TRANSMISSION,
      userAccessEnum.VISUALISER_EVALUATION,
      userAccessEnum.INVITER_SECOND_EVALUATEUR,
      userAccessEnum.SAISIR_AVIS_SECOND_EVALUATEUR,
      userAccessEnum.MODIFIER_AVIS_SECOND_EVALUATEUR,
      userAccessEnum.VISUALISER_AVIS_SECOND_EVALUATEUR,
      userAccessEnum.SAISIR_VALIDATION_EVALUATION,
      userAccessEnum.VISUALISATION_VALIDATION_EVALUATION,
      userAccessEnum.VALIDER_EVALUATION,
      userAccessEnum.SAISIR_AVIS_JURY,
    ],
  },
  {
    type: "private",
    //a modifie -> en fonction de l'url de la page de synthese d'un candidat
    //a modifie -> rajouter uuid
    path: "/admin/candidature/:id_candidature/:statut_candidature/:isListCandidatures",
    title: "Candidature",
    inNavbar: false,
    element: <CandidatureWorkflow />,
    allowedAccess: [
      userAccessEnum.SAISIR_CANDIDATURE,
      userAccessEnum.SAISIR_1ER_INFO_CANDIDATURE,
      userAccessEnum.SAISIR_PROFIL_CANDIDAT,
      userAccessEnum.INVITER_CANDIDAT_CREATION_CANDIDATURE,
      userAccessEnum.SAISIR_PARCOURS_CANDIDAT,
      userAccessEnum.CHOIX_BLOCS_CERTIF,
      userAccessEnum.VISUALISER_CHOIX_BLOCS_CERTIF,
      userAccessEnum.MODIFIER_CHOIX_BLOCS_CERTIF,
      userAccessEnum.IDENTIFICATION_PARTIES_PRENANTES_PARCOURS,
      userAccessEnum.VISUALISER_IDENTIFICATION_PARTIES_PRENANTES_PARCOURS,
      userAccessEnum.TRANSMISSION_DOSSIER_CANDIDATURE,
      userAccessEnum.VISUALISER_CANDIDATURE,
      userAccessEnum.MODIFIER_INFO_CANDIDATURE_AVANT_CERTIF,
      userAccessEnum.MODIFIER_INFO_CANDIDATURE_APRES_CERTIF,
      userAccessEnum.MODIFIER_PARCOURS_CANDIDAT,
      userAccessEnum.SAISIR_POSITIONNEMENT,
      userAccessEnum.MODIFIER_POSITIONNEMENT_AVANT_EVALUATION_OU_AVIS,
      userAccessEnum.VISUALISER_POSITIONNEMENT,
      userAccessEnum.SAISIR_AVIS_CANDIDAT_POSITIONNEMENT,
      userAccessEnum.MODIFIER_AVIS_CANDIDAT_POSITIONNEMENT,
      userAccessEnum.VISUALISER_AVIS_CANDIDAT_POSITIONNEMENT,
      userAccessEnum.CREER_AVIS_FORMATEUR,
      userAccessEnum.MODIFIER_EVALUATION_APRES_TRANSMISSION,
      userAccessEnum.VISUALISER_EVALUATION,
      userAccessEnum.INVITER_SECOND_EVALUATEUR,
      userAccessEnum.SAISIR_AVIS_SECOND_EVALUATEUR,
      userAccessEnum.MODIFIER_AVIS_SECOND_EVALUATEUR,
      userAccessEnum.VISUALISER_AVIS_SECOND_EVALUATEUR,
      userAccessEnum.SAISIR_VALIDATION_EVALUATION,
      userAccessEnum.VISUALISATION_VALIDATION_EVALUATION,
      userAccessEnum.SAISIR_AVIS_JURY,
    ],
  },
  {
    type: "private",
    path: "/admin/competences/:uuid",
    title: "Détail",
    inNavbar: false,
    element: <CompetenceDetail />,
    allowedAccess: [userAccessEnum.PARAMETRER_COMPETENCES],
  },
];
const publicRoutes = [
  {
    type: "public",
    path: "/inscription",
    title: "Inscription",
    inNavbar: false,
    element: <SignUp />,
    allowedAccess: ["*"],
  },
  {
    type: "public",
    path: "/non-autorise",
    title: "Non autorisé",
    inNavbar: false,
    element: <Unauthorized />,
    allowedAccess: ["*"],
  },
  {
    type: "public",
    path: "/reinitialisation-mot-de-passe",
    title: "Reset Password",
    inNavbar: false,
    element: <ResetPassword />,
    allowedAccess: ["*"],
  },
  {
    type: "public",
    path: "/creer-mot-de-passe",
    title: "Create Password",
    inNavbar: false,
    element: <CreatePassword />,
    allowedAccess: ["*"],
  },
  {
    type: "public",
    path: "/mot-de-passe-perdu",
    title: "Forget Password",
    inNavbar: false,
    element: <ForgetPassword />,
    allowedAccess: ["*"],
  },
  {
    type: "public",
    path: `/demande-aide`,
    title: "Demande d'aide",
    inNavbar: false,
    element: <ContactAdminPublic />,
    allowedAccess: ["*"],
  },
  {
    type: "public",
    path: "/cgu",
    title: "CGU",
    inNavbar: false,
    element: <GeneralPagePublic type="CGU" />,
    allowedAccess: ["*"],
  },
  {
    type: "public",
    path: "/mentions_legales",
    title: "Mentions légales",
    inNavbar: false,
    element: <GeneralPagePublic type="MENTIONS_LEGALES" />,
    allowedAccess: ["*"],
  },
  {
    type: "public",
    path: "/suivi_cohorte",
    title: "Oupss ",
    inNavbar: false,
    element: <PageSuiviCandidat />,
    allowedAccess: ["*"],
  },
  {
    type: "public",
    path: "/erreur",
    title: "Oupss ",
    inNavbar: false,
    element: <ErrorNotCatch />,
    allowedAccess: ["*"],
  },
  {
    type: "public",
    path: "*",
    title: "404 Page",
    inNavbar: false,
    element: <FourHundred />,
    allowedAccess: ["*"],
  },
];

export const routesConfig: IRoute[] = [
  ...privatePrimaryRoutes,
  ...privateSecondaryRoutes,
  ...publicRoutes,
];

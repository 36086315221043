import { atom, useAtom } from "jotai";
import { useQuery } from "react-query";
import { userSelectedBranchAtom } from "../../atoms/branchAtom";
import { branchesAtom } from "../../atoms/constantsAtom";
import { getCandidaturesRequest } from "../../services/candidatureService";
import useCandidatureWorkflow from "../../hooks/candidature/useCandidatureWorkflow";
import { getJuryAvailableForCandidature } from "../../services/juryServices";
import useCustomModal from "../../Common/Overlays/CustomModal/useCustomModal";
import ModificationCandidaturesJuryModal from "../../Common/InscriptionJuryModal/ModificationCandidatureJuryModal";
import {
  getHabilitationOfRequest,
  getHabilitationsUtilisateurDetailRequest,
} from "../../services/habilitationServices";
import useAccessSettings from "../../hooks/branchSettings/useAccessSettings";
import { branchAccessEnum, userAccessEnum } from "../../config/accessEnum";
import tools from "../../utils/tools";
import useAuth from "../../hooks/useAuth";
import DemandeHabilitationModal from "./DemandeHabilitationModal/DemandeHabilitationModal";
import useInfoProfilUser from "../../hooks/useInfoProfilUser";
import { useState } from "react";
import { getIndicateursDashboard } from "../../services/dashboardService";

const filtersAtom = atom<any>({
  en_attente_de_ma_part: false,
  statuts: [{ key: "inscription_jury" }],
});

const optionsFiltersAtom = atom({
  sort: "",
  page: 1,
  paginate: false,
  searchAt: "",
});

const habilitationOptionsFiltersAtom = atom({
  sort: "-date_fin",
  page: 1,
  paginate: true,
  searchAt: "",
});

export function useDashboard() {
  const [indicateursDashboard, setIndicateursDashboard]: any = useState(null);
  const [isLoadingIndicateurs, setIsLoadingIndicateurs] = useState(false);
  const [userSelectedBranch] = useAtom(userSelectedBranchAtom);
  const [branches] = useAtom<any>(branchesAtom);
  const branchRef: any = branches.find(
    (e: any) => e.id == userSelectedBranch.id
  );
  const [filters] = useAtom(filtersAtom);
  const [optionsFilters] = useAtom(optionsFiltersAtom);
  const [habilitationOptionsFilters, setHabilitationOptionsFilters] = useAtom(
    habilitationOptionsFiltersAtom
  );

  const { auth } = useAuth();

  const { ETAPES } = useCandidatureWorkflow();
  const { actions: modalActions } = useCustomModal();
  const { actions: accessActions } = useAccessSettings();
  const { isAdmin } = useInfoProfilUser();

  const {
    data: candidatures,
    isLoading: isLoadingCandidatures,
    refetch,
  } = useQuery(
    ["candidatures", optionsFilters, userSelectedBranch],
    () => getCandidaturesRequest(filters, optionsFilters),
    { retry: 0 }
  );

  const { data: juryList, isLoading: isLoadingJuryList } = useQuery(
    ["jurys", {}],
    () => getJuryAvailableForCandidature({}),
    { retry: 0 }
  );

  const { data: habilitationsOF, isLoading: isLoadingHabilitationsOF } =
    useQuery(
      ["habilitationsOF", userSelectedBranch, habilitationOptionsFilters],
      () => getHabilitationOfRequest({}, habilitationOptionsFilters),
      {
        retry: 0,
        enabled:
          accessActions.canI({
            action: branchAccessEnum.HABILITATION_SIMPLE_OF,
          }).granted && userSelectedBranch.profils.includes("OF"),
      }
    );

  const {
    data: habilitationsEvaluateur,
    isLoading: isLoadingHabilitationsEvaluateur,
  } = useQuery(
    ["habilitationsEvaluateur", userSelectedBranch, habilitationOptionsFilters],
    () =>
      getHabilitationsUtilisateurDetailRequest(
        { evaluateur: auth.user.uuid },
        habilitationOptionsFilters
      ),
    {
      retry: 0,
      enabled:
        accessActions.canI({
          action: branchAccessEnum.HABILITATION_STRICTE_EVALUATEUR,
        }).granted && userSelectedBranch.profils.includes("EVALUATEUR"),
    }
  );

  const {
    data: habilitationsPositionneur,
    isLoading: isLoadingHabilitationsPositionneur,
  } = useQuery(
    [
      "habilitationsPositionneur",
      userSelectedBranch,
      habilitationOptionsFilters,
    ],
    () =>
      getHabilitationsUtilisateurDetailRequest(
        { positionneur: auth.user.uuid },
        habilitationOptionsFilters
      ),
    {
      retry: 0,
      enabled:
        accessActions.canI({
          action: branchAccessEnum.HABILITATION_STRICTE_POSITIONNEUR,
        }).granted && userSelectedBranch.profils.includes("POSITIONNEUR"),
    }
  );

  const branchInformations = {
    nom: userSelectedBranch?.nom,
    description: branchRef?.description,
    hero: branchRef?.couleur_primaire,
    logo: branchRef?.logo_url,
    headband: branchRef?.headband_url,
  };

  const creationStatuses = ETAPES.find(
    (e: any) => e.nameType == "CANDIDATURE_SLIDER_ETAPE_CREATION"
  ).statuses.map((s: any) => s.id);

  const inscriptionsWithoutEnAttenteValidationJuryItems =
    candidatures?.data?.filter((c: any) => !c.jury);

  const dashboardData = [
    {
      title: "Candidatures par statut",
      data: [
        {
          libelle: indicateursDashboard?.CANDIDATURE_CREATION?.label,
          linkTo: "/admin/candidature?statuts[]=creation_candidature_etape",
          value: indicateursDashboard?.CANDIDATURE_CREATION?.total,
          paramActivated: true,
          userAuthorized: true,
        },
        {
          libelle: indicateursDashboard?.CANDIDATURE_POSITIONNEMENT?.label,
          linkTo: "/admin/candidature?statuts[]=positionnement_etape",
          value: indicateursDashboard?.CANDIDATURE_POSITIONNEMENT?.total,
          userAuthorized: true,
          paramActivated:
            accessActions.canI({
              action: branchAccessEnum.OBLIGER_POSITIONNEMENT,
            }).granted ||
            accessActions.canI({
              action:
                branchAccessEnum.AUTORISER_CREATEUR_CANDIDATURE_DEPOSER_POSITIONNEMENT,
            }).granted,
        },
        {
          libelle: indicateursDashboard?.CANDIDATURE_AVIS_FORMATEUR?.label,
          linkTo: "/admin/candidature?statuts[]=avis_formateur_etape",
          value: indicateursDashboard?.CANDIDATURE_AVIS_FORMATEUR?.total,
          userAuthorized: true,
          paramActivated: accessActions.canI({
            action: branchAccessEnum.ACTIVER_AVIS_FORMATEUR,
          }).granted,
        },
        {
          libelle: indicateursDashboard?.CANDIDATURE_EVALUATION?.label,
          linkTo: "/admin/candidature?statuts[]=evaluation_etape",
          value: indicateursDashboard?.CANDIDATURE_EVALUATION?.total,
          userAuthorized: true,
          paramActivated: true,
        },
        {
          libelle: indicateursDashboard?.CANDIDATURE_INSCRIPTION?.label,
          linkTo: "/admin/candidature?statuts[]=inscription_jury_etape",
          value: indicateursDashboard?.CANDIDATURE_INSCRIPTION?.total,
          userAuthorized: true,
          paramActivated: true,
        },
        {
          libelle: indicateursDashboard?.CANDIDATURE_DECISION_JURY?.label,
          linkTo: "/admin/candidature?statuts[]=decision_jury_etape",
          value: indicateursDashboard?.CANDIDATURE_DECISION_JURY?.total,
          userAuthorized: true,
          paramActivated: true,
        },
      ],
    },
    {
      title: "Candidatures sans activité",
      data: [],
    },
    {
      title: "Autres indicateurs",
      data: [
        {
          libelle:
            indicateursDashboard?.DEMANDE_INSCRIPTION_NON_TRAITEES?.label,
          linkTo: "/admin/demandes-inscription-utilisateurs?statut=EN_ATTENTE",
          value: indicateursDashboard?.DEMANDE_INSCRIPTION_NON_TRAITEES?.total,
          userAuthorized: accessActions.canI({
            action: userAccessEnum.VISUALISER_DEMANDE_INSCRIPTION_UTILISATEUR,
          }).granted,
          paramActivated: accessActions.canI({
            action:
              branchAccessEnum.ACTIVER_DEMANDE_CREATION_COMPTE_UTILISATEUR,
          }).granted,
        },
        {
          libelle:
            indicateursDashboard?.DEMANDE_HABILITATION_NON_TRAITEES?.label,
          linkTo: accessActions.canI({
            action: branchAccessEnum.HABILITATION_SIMPLE_OF,
          }).granted
            ? "/admin/demandes-habilitation-of?statut=EN_ATTENTE"
            : "/admin/demandes-habilitation-utilisateur?statut=EN_ATTENTE",
          value: indicateursDashboard?.DEMANDE_HABILITATION_NON_TRAITEES?.total,
          userAuthorized: isAdmin,
          paramActivated:
            accessActions.canI({
              action: branchAccessEnum.HABILITATION_SIMPLE_OF,
            }).granted ||
            accessActions.canI({
              action: branchAccessEnum.HABILITATION_STRICTE_EVALUATEUR,
            }).granted ||
            accessActions.canI({
              action: branchAccessEnum.HABILITATION_STRICTE_POSITIONNEUR,
            }).granted,
        },
        {
          libelle: indicateursDashboard?.SUIVI_COHORTE_A_RENSEIGNER?.label,
          linkTo: "/admin/suivi-cohortes?statut_6_mois=1",
          value: indicateursDashboard?.SUIVI_COHORTE_A_RENSEIGNER?.total,
          userAuthorized: accessActions.canI({
            action: userAccessEnum.MODIFIER_SUIVI_COHORTES,
          }).granted,
          paramActivated:
            accessActions.canI({
              action: branchAccessEnum.SUIVI_COHORTES_MODEL_OF_CANDIDAT,
            }).granted ||
            accessActions.canI({
              action: branchAccessEnum.SUIVI_COHORTES_MODEL_OF,
            }).granted ||
            accessActions.canI({
              action: branchAccessEnum.SUIVI_COHORTES_MODEL_ENTREPRISE_CANDIDAT,
            }).granted ||
            accessActions.canI({
              action: branchAccessEnum.SUIVI_COHORTES_MODEL_ENTREPRISE,
            }).granted,
        },
        {
          libelle: indicateursDashboard?.SUIVI_COHORTE_ENQUETES_TRAITEES?.label,
          linkTo: "/admin/suivi-cohortes?statut_6_mois=2",
          value: indicateursDashboard?.SUIVI_COHORTE_ENQUETES_TRAITEES?.total,
          userAuthorized: accessActions.canI({
            action: userAccessEnum.MODIFIER_SUIVI_COHORTES,
          }).granted,
          paramActivated:
            accessActions.canI({
              action: branchAccessEnum.SUIVI_COHORTES_MODEL_OF_CANDIDAT,
            }).granted ||
            accessActions.canI({
              action: branchAccessEnum.SUIVI_COHORTES_MODEL_OF,
            }).granted ||
            accessActions.canI({
              action: branchAccessEnum.SUIVI_COHORTES_MODEL_ENTREPRISE_CANDIDAT,
            }).granted ||
            accessActions.canI({
              action: branchAccessEnum.SUIVI_COHORTES_MODEL_ENTREPRISE,
            }).granted,
        },
      ],
    },
  ].map((section: any) => ({
    ...section,
    data: section.data.filter(
      (indicateur: any) =>
        indicateur.userAuthorized && indicateur.paramActivated
    ),
  }));

  const actions = {
    getIndicateursDashboard: async () => {
      setIsLoadingIndicateurs(true);
      const response = await getIndicateursDashboard();
      setIndicateursDashboard(response.data);
      setIsLoadingIndicateurs(false);
    },
    inscriptionCandidaturesJury: () => {
      modalActions.openModal({
        header: "Inscrire mes candidatures au jury",
        body: (
          <ModificationCandidaturesJuryModal
            candidatures={inscriptionsWithoutEnAttenteValidationJuryItems}
            onSaved={(jury: any) => {
              refetch();
            }}
            isModification={false}
          />
        ),
        fullWidth: true,
      });
    },
    changePage: (page: number) => {
      actions.onChangeHabilitationOptionFilters({
        ...habilitationOptionsFilters,
        page,
      });
    },
    onChangeHabilitationOptionFilters: (newOptionsFilters: any) => {
      setHabilitationOptionsFilters(newOptionsFilters);
    },
    sortColumn: (name: string) => {
      const sortArray = habilitationOptionsFilters.sort.split(",");
      const sortJoin = tools.sortColumnRefactoring(sortArray, name);

      actions.onChangeHabilitationOptionFilters({
        ...habilitationOptionsFilters,
        sort: sortJoin,
      });
    },
    onOpenDemandeHabilitationModal: (
      entityOrProfil: "OF" | "EVALUATEUR" | "POSITIONNEUR"
    ) => {
      modalActions.openModal({
        header: "Demander une habilitation",
        body: <DemandeHabilitationModal entityOrProfil={entityOrProfil} />,
        fullWidth: true,
      });
    },
  };

  return {
    habilitationsOF,
    habilitationsEvaluateur,
    habilitationsPositionneur,
    habilitationOptionsFilters,
    isLoadingJuryList,
    isLoadingHabilitations:
      isLoadingHabilitationsOF ||
      isLoadingHabilitationsEvaluateur ||
      isLoadingHabilitationsPositionneur,
    branchInformations,
    creationStatuses,
    juryList,
    actions,
    isLoadingCandidatures,
    dashboardData,
    isLoadingIndicateurs,
  };
}

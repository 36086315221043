import { ChoiceGroup, Stack, Text } from "@fluentui/react";
import { branchAccessEnum } from "../../config/accessEnum";
import { StatutCandidature } from "../../hooks/candidature/useCandidatureWorkflow";
import useConstants from "../../hooks/useConstants";
import Can from "../Can/Can";
import DecisionEvaluation from "../DecisionEvaluation/DecisionEvaluation";
import EvaluationInput from "../EvaluationInput/EvaluationInput";
import useTheme from "../useTheme";
import Decision from "./Decision/Decision";
import DecisionInputs from "./DecisionInputs/DecisionInputs";
import { getClassNames } from "./component.classnames";
import { styles } from "./component.styles";
import Accordion from "../Accordion/Accordion";
import useAccessSettings from "../../hooks/branchSettings/useAccessSettings";

enum DecisionsEnum {
  BESOIN_DE_FORMATION,
  VALIDE,
  NON_VALIDE,
  NON_RENSEIGNE,
  MAITRISE,
}

export interface ICompetenceBlocProps {
  bloc: any;
  status: StatutCandidature;
  viewDecision: boolean;
  reactHookProps: any;
  onChangeBloc: any;
  disabled: boolean;
  labels: any;
}

function CompetenceBloc({
  bloc,
  status,
  viewDecision,
  reactHookProps,
  onChangeBloc = () => {},
  disabled,
  labels,
  candidature,
  isPresident = false,
  index,
  isUpdateAll = false,
}: any) {
  //ICompetenceBlocProps
  const {
    reference,
    libelle,
    description,
    statut_libelle: type,
    decision_positionnement,
    commentaire_positionnement,
    decision_evaluation,
    commentaire_evaluation,
    decision_president,
    commentaire_president,
    competences,
  } = bloc;
  const { actions: accessActions } = useAccessSettings();
  const { primaryColor } = useTheme();
  const { constants } = useConstants();
  const { table, tr, leftCell, rightCell, typeBloc, trLight, leftCellLight } =
    getClassNames();

  const showEvaluateurRow = !(
    status == StatutCandidature.CREATION_CANDIDATURE ||
    status == StatutCandidature.VALIDATION_DOSSIER_VAE ||
    status == StatutCandidature.POSITIONNEMENT ||
    status == StatutCandidature.VALIDATION_POSITIONNEMENT ||
    status == StatutCandidature.AVIS_FORMATEUR
  );

  const showPresidentRow =
    showEvaluateurRow &&
    ((status == StatutCandidature.DECISION_JURY && isPresident) ||
      status == StatutCandidature.RESULTATS_JURY);

  const handleChangeBloc = (e: any) => {
    const { name, value } = e.target;
    const newBloc = {
      ...bloc,
      [name]: value,
    };
    onChangeBloc(newBloc);
  };

  const handleChangeCompetence = (
    competenceId: any,
    e: any,
    isCompetenceCle: boolean = false
  ) => {
    const { name, value } = e.target;
    const newBloc = {
      ...bloc,
      competences: bloc.competences.map((competence: any) => {
        if (competence.uuid === competenceId) {
          return { ...competence, [name]: value };
        }
        return competence;
      }),
    };

    if (
      isCompetenceCle &&
      status == StatutCandidature.EVALUATION &&
      value == "NON_VALIDE"
    ) {
      newBloc.decision_evaluation = "NON_VALIDE";
    }
    onChangeBloc(newBloc);
  };

  const handleChangeCritere = (
    competenceId: any,
    critereLibelle: any,
    e: any
  ) => {
    const { name, value } = e.target;
    const newBloc = {
      ...bloc,
      competences: bloc.competences.map((competence: any) => {
        if (competence.uuid === competenceId) {
          return {
            ...competence,
            criteres: competence.criteres.map((critere: any) => {
              if (critere.libelle === critereLibelle) {
                return { ...critere, [name]: value };
              }
              return critere;
            }),
          };
        }
        return competence;
      }),
    };
    onChangeBloc(newBloc);
  };

  const isCompetenceCleNonValide =
    [...competences].filter(
      (c: any) => c.competence_cle && c.decision_evaluation == "NON_VALIDE"
    ).length > 0;
  const blocCompetenceDecisionDisabled =
    isCompetenceCleNonValide && status == StatutCandidature.EVALUATION;

  const isCritereEvaluationUncomplete = () => {
    var isUncomplete = false;
    if (
      status == "evaluation" &&
      accessActions.canI({
        action: branchAccessEnum.EVALUATION_PAR_CRITERE,
      }).granted
    ) {
      isUncomplete = competences
        .map((competence: any) =>
          competence?.criteres
            ?.map((critere: any) => critere.decision_evaluation)
            .includes(null)
        )
        .includes(true);
    }
    return isUncomplete;
  };

  return (
    <Stack
      styles={styles.container}
      className="ms-bgColor-white competenceBlocSm"
      tokens={{ childrenGap: 10 }}
      style={{
        margin: "30px 0",
        marginTop: 0,
        borderRight: `4px solid ${primaryColor}`,
        paddingTop: 15,
        // borderBottom: `2px solid ${primaryColor}`,
        boxShadow: "-0px 0px 7px -1px #d6d6d6",
      }}
    >
      <Stack>
        <Stack horizontal horizontalAlign="space-between">
          <Text
            style={{ color: primaryColor, fontWeight: "700", fontSize: 19 }}
          >
            {index}
            {". "}
            {reference && reference + " - "}
            {libelle}
          </Text>
          <div className={typeBloc}>{type}</div>
        </Stack>
        <Text variant="mediumPlus" style={{ color: "#6F6F6F" }}>
          {description}
        </Text>
      </Stack>
      {viewDecision && (
        <>
          <Stack>
            <table className={table}>
              <tbody>
                {candidature.positionnement_par_candidat && (
                  <tr className={tr}>
                    <td className={leftCell}>{labels.POSITIONNEMENT_BLOC}</td>
                    <td className={rightCell}>
                      {status == StatutCandidature.POSITIONNEMENT ? (
                        <DecisionInputs
                          disabled={disabled}
                          choiceGroupeOptions={
                            constants["CANDIDATURES"][
                              "DECISIONS_POSITIONNEMENT"
                            ]
                          }
                          radioLabel={""}
                          radioInputName={"decision_positionnement"}
                          textfieldInputLabel={
                            "Commentaire du positionneur: (1000 caractères max)"
                          }
                          textfieldInputName={"commentaire_positionnement"}
                          textfieldInputPlaceholder={
                            "Ex: Commentaire sur le positionnement de ce bloc de compétences"
                          }
                          defaultValues={{
                            decision: decision_positionnement,
                            commentaire: commentaire_positionnement,
                          }}
                          onChange={handleChangeBloc}
                        />
                      ) : (
                        <Decision
                          decision={
                            decision_positionnement == "MAITRISE"
                              ? DecisionsEnum.MAITRISE
                              : decision_positionnement == "BESOIN_DE_FORMATION"
                              ? DecisionsEnum.BESOIN_DE_FORMATION
                              : DecisionsEnum.NON_RENSEIGNE
                          }
                          commentTitle={"Commentaire du positionneur :"}
                          comment={commentaire_positionnement}
                        />
                      )}
                    </td>
                  </tr>
                )}
                {showEvaluateurRow && (
                  <tr className={tr}>
                    <td className={leftCell}>{labels.EVALUATION_BLOC}</td>
                    <td className={rightCell}>
                      {status == StatutCandidature.EVALUATION ? (
                        <DecisionInputs
                          disabled={disabled}
                          competenceCleNonValide={
                            blocCompetenceDecisionDisabled
                          }
                          defaultValues={{
                            decision: decision_evaluation,
                            commentaire: commentaire_evaluation,
                          }}
                          choiceGroupeOptions={
                            constants["CANDIDATURES"]["DECISIONS_EVALUATION"]
                          }
                          radioLabel={""}
                          radioInputName={"decision_evaluation"}
                          textfieldInputLabel={
                            "Commentaire de l'évaluateur: (1000 caractères max)"
                          }
                          textfieldInputName={"commentaire_evaluation"}
                          textfieldInputPlaceholder={
                            "Ex: Commentaire sur l'évaluation de ce bloc de compétences"
                          }
                          onChange={handleChangeBloc}
                        />
                      ) : (
                        <Decision
                          decision={
                            decision_evaluation == "VALIDE"
                              ? DecisionsEnum.VALIDE
                              : DecisionsEnum.NON_VALIDE
                          }
                          commentTitle={"Commentaire de l'évaluateur :"}
                          comment={commentaire_evaluation}
                        />
                      )}
                    </td>
                  </tr>
                )}
                {showPresidentRow && (
                  <tr className={tr}>
                    <td className={leftCell}>Décision du président</td>
                    <td className={rightCell}>
                      {status == StatutCandidature.RESULTATS_JURY ? (
                        <Decision
                          decision={
                            decision_president == "VALIDE"
                              ? DecisionsEnum.VALIDE
                              : DecisionsEnum.NON_VALIDE
                          }
                          commentTitle="Commentaire du président :"
                          comment={commentaire_president}
                        />
                      ) : (
                        <DecisionInputs
                          disabled={disabled}
                          defaultValues={{
                            decision: decision_president,
                            commentaire: commentaire_president,
                          }}
                          choiceGroupeOptions={
                            constants["CANDIDATURES"]["DECISIONS_JURY"]
                          }
                          radioLabel={""}
                          radioInputName={"decision_president"}
                          textfieldInputLabel={
                            "Commentaire du président : (1000 caractères max)"
                          }
                          textfieldInputName={"commentaire_president"}
                          textfieldInputPlaceholder={
                            "Ex: Commentaire sur la décision de ce bloc de compétences"
                          }
                          onChange={handleChangeBloc}
                        />
                      )}
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </Stack>

          <Stack>
            <Stack>
              <ol
                style={{
                  paddingLeft: 0,
                  marginTop: 0,
                }}
              >
                <Accordion
                  horizontalAlign={"start"}
                  key={labels.COMPETENCES_ASSOCIEES + `${index}`}
                  title={labels.COMPETENCES_ASSOCIEES}
                  noImg
                  defaultIsOpen={(() => {
                    if (
                      status == "resultats_jury" ||
                      reactHookProps.watch("statut") != status
                    ) {
                      return false;
                    }

                    enum KEY_DECISION_DEPENDS_ON_STATUT {
                      "positionnement" = "decision_positionnement",
                      "evaluation" = "decision_evaluation",
                      "decision_jury" = "decision_president",
                    }

                    const competencesDecision = competences?.map(
                      (competence: any) =>
                        competence[
                          (KEY_DECISION_DEPENDS_ON_STATUT as any)[status]
                        ]
                    );

                    const isCompetenceUncomplete =
                      competencesDecision?.filter((c: any) => !c).length > 0;

                    //si statut n'est pas evaluation ou sans critere
                    if (
                      status != "evaluation" ||
                      !accessActions.canI({
                        action: branchAccessEnum.EVALUATION_PAR_CRITERE,
                      }).granted
                    ) {
                      return isCompetenceUncomplete;
                    }

                    const isCritereUncomplete = isCritereEvaluationUncomplete();

                    return isCompetenceUncomplete || isCritereUncomplete;
                  })()}
                  forceClose={(() => {
                    const isCritereUncomplete = isCritereEvaluationUncomplete();

                    return isUpdateAll && !isCritereUncomplete;
                  })()}
                  styles={{
                    content: {
                      root: {},
                    },
                  }}
                  content={competences?.map((competence: any) => {
                    const competenceDecisionPositionnement =
                      competence.decision_positionnement;
                    const competenceDecisionEvaluation =
                      competence.decision_evaluation;
                    const criteres = competence?.criteres;
                    const competenceDecisionJury =
                      competence.decision_president;
                    const name = competence.libelle;
                    return (
                      <li
                        key={competence.uuid}
                        style={{
                          background: "#fdfdfc",
                          borderRight: "5px solid rgb(217, 217, 217)",
                          padding: 10,
                          marginBottom: 8,
                          borderRadius: 8,
                        }}
                      >
                        <Text style={{ fontWeight: "bold" }}>
                          {name}{" "}
                          {competence.competence_cle &&
                            status == StatutCandidature.EVALUATION && (
                              <i style={{ fontWeight: 300, fontSize: "12px" }}>
                                (cette compétence est clé pour obtenir le bloc
                                de compétence : sa non-validation entraine une
                                non-validation du bloc)
                              </i>
                            )}
                        </Text>
                        <table className={table}>
                          <tbody>
                            {candidature.positionnement_par_candidat && (
                              <Can
                                I={
                                  branchAccessEnum.POSITIONNEMENT_PAR_COMPETENCE
                                }
                              >
                                <tr className={tr}>
                                  <td className={leftCell}>
                                    {
                                      labels.COMPETENCES_ASSOCIEES_POSITIONNEMENT
                                    }
                                  </td>
                                  <td className={rightCell}>
                                    {status ==
                                    StatutCandidature.POSITIONNEMENT ? (
                                      <ChoiceGroup
                                        disabled={disabled}
                                        styles={{
                                          flexContainer: {
                                            display: "flex",
                                            flexWrap: "wrap",
                                          },
                                        }}
                                        selectedKey={
                                          competenceDecisionPositionnement ??
                                          null
                                        }
                                        options={
                                          constants["CANDIDATURES"][
                                            "DECISIONS_POSITIONNEMENT"
                                          ]
                                        }
                                        required={true}
                                        name={"decision_positionnement"}
                                        checked={false}
                                        onChange={(e: any, option: any) =>
                                          handleChangeCompetence(
                                            competence.uuid,
                                            {
                                              target: {
                                                name: e.target.name,
                                                value: option.key,
                                              },
                                            }
                                          )
                                        }
                                      />
                                    ) : (
                                      <Decision
                                        decision={
                                          competenceDecisionPositionnement ==
                                          "MAITRISE"
                                            ? DecisionsEnum.MAITRISE
                                            : competenceDecisionPositionnement ==
                                              "BESOIN_DE_FORMATION"
                                            ? DecisionsEnum.BESOIN_DE_FORMATION
                                            : DecisionsEnum.NON_RENSEIGNE
                                        }
                                      />
                                    )}
                                  </td>
                                </tr>
                              </Can>
                            )}
                            {showEvaluateurRow && (
                              <tr className={tr}>
                                <td colSpan={2}>
                                  <table className={table}>
                                    <tbody>
                                      <tr className={tr}>
                                        <td className={leftCell}>
                                          <Text style={{ fontSize: 13 }}>
                                            {
                                              labels.COMPETENCES_ASSOCIEES_EVALUATION
                                            }
                                          </Text>
                                        </td>
                                        <td className={rightCell}>
                                          {status ==
                                          StatutCandidature.EVALUATION ? (
                                            <ChoiceGroup
                                              disabled={disabled}
                                              selectedKey={
                                                competenceDecisionEvaluation ??
                                                null
                                              }
                                              styles={{
                                                flexContainer: {
                                                  display: "flex",
                                                  flexWrap: "wrap",
                                                },
                                              }}
                                              options={
                                                constants["CANDIDATURES"][
                                                  "DECISIONS_EVALUATION"
                                                ]
                                              }
                                              required={true}
                                              name={"decision_evaluation"}
                                              checked={false}
                                              onChange={(e: any, option: any) =>
                                                handleChangeCompetence(
                                                  competence.uuid,
                                                  {
                                                    target: {
                                                      name: e.target.name,
                                                      value: option.key,
                                                    },
                                                  },
                                                  competence.competence_cle
                                                )
                                              }
                                            />
                                          ) : (
                                            <Decision
                                              decision={
                                                competenceDecisionEvaluation ==
                                                "VALIDE"
                                                  ? DecisionsEnum.VALIDE
                                                  : competenceDecisionEvaluation ==
                                                    "NON_VALIDE"
                                                  ? DecisionsEnum.NON_VALIDE
                                                  : DecisionsEnum.NON_RENSEIGNE
                                              }
                                            />
                                          )}
                                        </td>
                                      </tr>
                                      <Can
                                        I={
                                          branchAccessEnum.EVALUATION_PAR_CRITERE
                                        }
                                      >
                                        {criteres?.map(
                                          (criteria: any, index: any) => (
                                            <tr className={trLight}>
                                              <td className={leftCell}>
                                                <Text
                                                  variant={"small"}
                                                  style={{ color: "#6F6F6F" }}
                                                  key={index}
                                                  id={`critere-${index}`}
                                                >
                                                  {criteria?.libelle}
                                                </Text>
                                              </td>
                                              <td className={rightCell}>
                                                {status ==
                                                StatutCandidature.EVALUATION ? (
                                                  <EvaluationInput
                                                    key={index}
                                                    modalites_evaluation={
                                                      candidature?.certification
                                                        ?.modalites_evaluation
                                                    }
                                                    inputName={
                                                      "evaluation.criteria" +
                                                      index
                                                    }
                                                    disabled={disabled}
                                                    reactHookProps={
                                                      reactHookProps
                                                    }
                                                    defaultValue={
                                                      criteria?.decision_evaluation
                                                    }
                                                    onChange={(value: any) =>
                                                      handleChangeCritere(
                                                        competence.uuid,
                                                        criteria.libelle,
                                                        {
                                                          target: {
                                                            name: "decision_evaluation",
                                                            value: value,
                                                          },
                                                        }
                                                      )
                                                    }
                                                  />
                                                ) : (
                                                  <DecisionEvaluation
                                                    key={index}
                                                    modalites_evaluation={
                                                      candidature?.certification
                                                        ?.modalites_evaluation
                                                    }
                                                    decision={
                                                      criteria.decision_evaluation ??
                                                      "NON_RENSEIGNE"
                                                    }
                                                  />
                                                )}
                                              </td>
                                            </tr>
                                          )
                                        ) || <></>}
                                      </Can>
                                    </tbody>
                                  </table>
                                </td>
                              </tr>
                            )}
                            {showPresidentRow && (
                              <tr className={tr}>
                                <td className={leftCell}>
                                  Décision du président
                                </td>
                                <td className={rightCell}>
                                  {status ==
                                  StatutCandidature.RESULTATS_JURY ? (
                                    <Decision
                                      decision={
                                        competenceDecisionJury == "VALIDE"
                                          ? DecisionsEnum.VALIDE
                                          : competenceDecisionJury ==
                                            "NON_VALIDE"
                                          ? DecisionsEnum.NON_VALIDE
                                          : DecisionsEnum.NON_RENSEIGNE
                                      }
                                    />
                                  ) : (
                                    <ChoiceGroup
                                      disabled={disabled}
                                      selectedKey={
                                        competenceDecisionJury ?? null
                                      }
                                      styles={{
                                        flexContainer: {
                                          display: "flex",
                                          flexWrap: "wrap",
                                        },
                                      }}
                                      options={
                                        constants["CANDIDATURES"][
                                          "DECISIONS_JURY"
                                        ]
                                      }
                                      required={true}
                                      name={"decision_president"}
                                      checked={false}
                                      onChange={(e: any, option: any) =>
                                        handleChangeCompetence(
                                          competence.uuid,
                                          {
                                            target: {
                                              name: e.target.name,
                                              value: option.key,
                                            },
                                          }
                                        )
                                      }
                                    />
                                  )}
                                </td>
                              </tr>
                            )}
                          </tbody>
                        </table>
                      </li>
                    );
                  })}
                />
              </ol>
            </Stack>
          </Stack>
        </>
      )}
    </Stack>
  );
}

export default CompetenceBloc;

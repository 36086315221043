import { atom, useAtom } from "jotai";
import { toast } from "react-toastify";
import {
  candidaturesListAtom,
  candidaturesNeedActionListAtom,
  isConsultCandidaturesListAtom,
} from "../../Pages/CandidatJuryList/useCandidatureJuryList";
import { isOpenedInModalAtom } from "../../Pages/CandidatureWorkflow/CandidatureWorkflow";
import usePreviousNextCandidature, {
  indiceCandidatureActualAtom,
} from "../../Pages/CandidatureWorkflow/PreviousNextCandidature/usePreviousNextCandidature";
import useWorkflowNavigate from "../../Pages/CandidatureWorkflow/useWorkflowNavigate";
import candidatureAdapters from "../../adapters/candidatureAdapters";
import { userSelectedBranchAtom } from "../../atoms/branchAtom";
import { branchAccessEnum } from "../../config/accessEnum";
import { IS_OFFLINE } from "../../config/offlineConfig";
import {
  deleteDecisionPresident,
  getCandidatureRequest,
  getMECRecipients,
  postAvisMembreJury,
  postCandidatureRequest,
  putCandidatureWithFormDataRequest,
  putEnregistrerAvisFormateurCandidature,
  putEnregistrerEvaluationCandidature,
  putEnregistrerPositionnementCandidature,
} from "../../services/candidatureService";
import tools from "../../utils/tools";
import useAccessSettings from "../branchSettings/useAccessSettings";
import useDynamicFields from "../dynamicFields/useDynamicFields";
import useOfflineCandidaturePile from "../offlineCandidature/useOfflineCandidaturePile";
import useAuth from "../useAuth";
import useCandidatureMutations from "./useCandidatureMutations";

export const candidatureAtom = atom({
  statut: "creation_candidature",
  candidat: {},
  positionnement_par_candidat: false,
  attestation_dernier_diplome_deleted: false,
});
export const isLoadingCandidatureAtom = atom(false);

function useCandidature() {
  const { navigate } = useWorkflowNavigate();
  const { actions: accessActions } = useAccessSettings();
  const { auth }: any = useAuth();
  const [candidature, setCandidature]: any = useAtom(candidatureAtom);
  const [isOpenedInModal] = useAtom(isOpenedInModalAtom);
  const { candidatureUuid, setCandidatureUuid, mutations } =
    useCandidatureMutations();
  const { dynamicFields } = useDynamicFields();
  const { actions: pileActions } = useOfflineCandidaturePile();
  const [userSelectedBranch] = useAtom(userSelectedBranchAtom);
  const { actions: actionsPreviousNextCandidature } =
    usePreviousNextCandidature();

  const generalConditionnalDisplayVariables = {
    isPresidentForThisCandidature:
      candidature.jury?.president_jury_uuid == auth.user.uuid,
    isMembreForThisCandidature: candidature.jury?.membres?.find(
      (m: any) => m.uuid == auth.user.uuid
    ),
  };

  const [candidaturesList, setCandidaturesList]: any =
    useAtom<any>(candidaturesListAtom);
  const [isConsultCandidaturesList, setIsConsultCandidaturesList] = useAtom(
    isConsultCandidaturesListAtom
  );
  const [candidaturesNeedActionList] = useAtom(candidaturesNeedActionListAtom);
  const [indiceCandidatureActual]: any = useAtom(indiceCandidatureActualAtom);
  const [, setIsLoadingCandidature] = useAtom(isLoadingCandidatureAtom);

  const actions = {
    resetIsConsultCandidaturesList: () => {
      setIsConsultCandidaturesList(false);
    },
    resetCandidature: () => {
      setCandidature({ statut: "creation_candidature", candidat: {} });
    },
    setUpdateCandidatureUuid: (data: any) => {
      setCandidatureUuid(data);
    },
    patchGiveUpCandidature: async (candidatureUuid: any) => {
      mutations.PatchGiveUpCandidature.mutate(candidatureUuid);
    },
    getCandidatureDetail: async (candidatureId: any) => {
      let candidatureData = null;
      if (IS_OFFLINE) {
        candidatureData = pileActions.getLocalCandidatureByUuid(candidatureId);
      } else {
        setIsLoadingCandidature(true);
        candidatureData = await getCandidatureRequest(candidatureId);
        setIsLoadingCandidature(false);
      }
      setCandidature(candidatureData);
      return candidatureData;
    },
    postCandidature: async (data: any, reactHookProps: any) => {
      try {
        const isCandidatPartiePrenante = accessActions.canI({
          action: branchAccessEnum.INVITER_CANDIDAT_A_L_INSCRIPTION,
        }).granted;
        const newCandidature: any = await postCandidatureRequest(
          candidatureAdapters.transformForAPI(
            data,
            null,
            isCandidatPartiePrenante
          )
        );
        if (!isOpenedInModal) {
          navigate(
            `/admin/candidature/${newCandidature.uuid}/${newCandidature.statut}`
          );
        } else {
          navigate({
            id_candidature: newCandidature.uuid,
            statut_candidature: newCandidature.statut,
          });
        }
      } catch (err: any) {
        const errorData = err.response?.data;
        if (errorData?.message) {
          toast.error(err.response.data.message);
        }
        if (errorData?.errors) {
          tools.mapOnApiErrors(errorData.errors, reactHookProps);
        }
      }
    },
    putCandidature: async (
      data: any,
      STATUT_PAGE: any,
      reactHookProps: any
    ) => {
      try {
        const newCandidature = await putCandidatureWithFormDataRequest(
          candidatureAdapters.transformForAPI(
            {
              ...data,
              statut_a_modifier: data.statut_a_modifier
                ? data.statut_a_modifier
                : STATUT_PAGE.id,
              _method: "PUT",
            },
            dynamicFields
          ),
          data.uuid
        );
        toast.success("Informations enregistrées avec succès.");
        if (!isOpenedInModal) {
          navigate(
            `/admin/candidature/${newCandidature.uuid}/${newCandidature.statut}`
          );
        } else {
          navigate({
            id_candidature: newCandidature.uuid,
            statut_candidature: newCandidature.statut,
          });
        }
      } catch (err: any) {
        const errorData = err.response?.data;
        if (errorData?.message) {
          toast.error(err.response.data.message);
        }
        if (errorData?.errors) {
          tools.mapOnApiErrors(errorData.errors, reactHookProps);
        }
      }
    },
    putCandidatureFormData: async (
      data: any,
      STATUT_PAGE: any,
      reactHookProps: any,
      isGoToNextCandidature: boolean = false
    ) => {
      try {
        const isEnregistrer =
          data.isEnregistrerEvaluation ||
          data.isEnregistrerPositionnement ||
          data.isEnregistrerAvisFormateur;
        const formData = new FormData();
        tools.buildFormData(
          formData,
          candidatureAdapters.transformForAPI(
            {
              ...data,
              statut_a_modifier: data.statut_a_modifier
                ? data.statut_a_modifier
                : STATUT_PAGE.id,
              _method: isEnregistrer ? "POST" : "PUT",
            },
            dynamicFields,
            false,
            isEnregistrer
          ),
          null
        );
        const newCandidature = data.isEnregistrerEvaluation
          ? await putEnregistrerEvaluationCandidature(formData, data.uuid)
          : data.isEnregistrerAvisFormateur
          ? await putEnregistrerAvisFormateurCandidature(formData, data.uuid)
          : data.isEnregistrerPositionnement
          ? await putEnregistrerPositionnementCandidature(formData, data.uuid)
          : await putCandidatureWithFormDataRequest(formData, data.uuid);

        toast.success("Informations enregistrées avec succès.");

        if (!isConsultCandidaturesList) {
          navigate(
            `/admin/candidature/${newCandidature.uuid}/${newCandidature.statut}`
          );
          return;
        } else {
          const candidatureUpdated = candidaturesList.find(
            (c: any) => c.uuid == newCandidature.uuid
          );
          setCandidaturesList(
            candidaturesList.map((c: any) =>
              c.uuid == candidatureUpdated.uuid
                ? {
                    ...newCandidature,
                    isTreated: true,
                  }
                : c
            )
          );
        }

        if (
          isGoToNextCandidature &&
          indiceCandidatureActual != candidaturesNeedActionList.length - 1
        ) {
          actionsPreviousNextCandidature.onNextCandidature();
          return;
        }

        if (!isOpenedInModal) {
          navigate(
            isConsultCandidaturesList
              ? `/admin/candidature/${newCandidature.uuid}/${newCandidature.statut}/true`
              : `/admin/candidature/${newCandidature.uuid}/${newCandidature.statut}`
          );
          return;
        }

        navigate({
          id_candidature: newCandidature.uuid,
          statut_candidature: newCandidature.statut,
        });
      } catch (err: any) {
        const errorData = err.response?.data;
        if (errorData?.message) {
          toast.error(err.response.data.message);
        }
        if (errorData?.errors) {
          tools.mapOnApiErrors(errorData.errors, reactHookProps);
        }
      }
    },
    patchAcceptCandidature: async (candidatureUuid: any) => {
      mutations.PatchAcceptCandidatureRequest.mutate(candidatureUuid);
    },
    patchRejectCandidature: async (candidatureUuid: any) => {
      mutations.PatchRejectCandidatureRequest.mutate(candidatureUuid);
    },
    patchDesinscireCandidature: async (
      candidatureUuid: any,
      options: any = undefined
    ) => {
      mutations.PatchDesinscrireCandidatureRequest.mutate(
        candidatureUuid,
        options
      );
    },
    patchModifyCandidatureJury: async (data: any) => {
      const juryObject = {
        jury: data.jury.uuid,
      };
      mutations.PatchModifyCandidatureJuryRequest.mutate({
        candidatureUuid: data.uuid,
        jury: juryObject,
      });
    },
    postAvisMembreJury: async (
      avis: any,
      candidatureUuid: any,
      reactHookProps: any
    ) => {
      try {
        await postAvisMembreJury(avis, candidatureUuid);
        const candidatureUpdated = candidaturesList.find(
          (c: any) => c.uuid == candidatureUuid
        );
        setCandidaturesList(
          candidaturesList.map((c: any) =>
            c.uuid == candidatureUpdated.uuid
              ? {
                  ...c,
                  isTreated: true,
                }
              : c
          )
        );
        toast.success("Avis enregistrées avec succès.");
      } catch (err: any) {
        const errorData = err.response?.data;
        if (errorData?.message) {
          toast.error(err.response.data.message);
        }
        if (errorData?.errors) {
          tools.mapOnApiErrors(errorData.errors, reactHookProps);
        }
      }
    },
    deleteDesicionPresident: async (candidatureUuid: any) => {
      await deleteDecisionPresident(candidatureUuid);
    },
    getMECRecipients: async (filters: any) => {
      try {
        const recipients = await getMECRecipients(filters);
        return recipients.data;
      } catch (err: any) {
        const errorData = err.response?.data;
        if (errorData?.message) {
          toast.error(err.response.data.message);
        }
      }

      // return new Promise((resolve) => {
      //   setTimeout(() => {
      //     resolve([
      //       {
      //         nom: "Marceau",
      //         prenom: "Joan",
      //         email: "joan@10positif.com",
      //         roles: [
      //           "Administrateur de branche",
      //           "Organisme de formation",
      //           "Entreprise",
      //         ],
      //         uuid: "1",
      //       },
      //       {
      //         nom: "Marceau",
      //         prenom: "Jean-Guillaume",
      //         email: "joan.guillaume@10positif.com",
      //         roles: [
      //           "Administrateur de branche",
      //           "Organisme de formation",
      //           "Entreprise",
      //         ],
      //         uuid: "2",
      //       },
      //     ]);
      //   }, 2000);
      // });
    },
  };

  const {
    isLoading: isLoadingPostCandidature,
    isError: isErrorPostCandidature,
    error: errorPostCandidature,
    isSuccess: isSuccessPostCandidature,
  } = mutations.PostCandidature;

  const {
    isLoading: isLoadingModifyCandidatureJury,
    isSuccess: isSuccessModifyCandidatureJury,
    isError: isErrorModifyCandidatureJury,
  } = mutations.PatchModifyCandidatureJuryRequest;

  const {
    isLoading: isLoadingAcceptCandidature,
    isSuccess: isSuccessAcceptCandidature,
    isError: isErrorAcceptCandidature,
  } = mutations.PatchAcceptCandidatureRequest;

  const {
    isLoading: isLoadingRejectCandidature,
    isSuccess: isSuccessRejectCandidature,
    isError: isErrorRejectCandidature,
  } = mutations.PatchRejectCandidatureRequest;

  const { isLoading: isLoadingDesinscrireCandidature } =
    mutations.PatchDesinscrireCandidatureRequest;

  const candidatureSelectableBlocs =
    candidature?.certification?.blocs_competences
      .filter(
        (bloc: any) =>
          bloc.option_uuid == candidature.option_selectionnee &&
          !bloc.deja_acquis_dans_une_precedente_certification
      )
      .map((bloc: any) =>
        candidature.candidat.blocs_competences_valides?.find(
          (blocValide: any) => blocValide.uuid == bloc.uuid
        )
          ? candidature.blocs_competences_passes.find(
              (blocValide: any) => blocValide.uuid == bloc.uuid
            )
            ? bloc
            : { ...bloc, deja_acquis_dans_une_precedente_certification: true }
          : bloc
      ) || [];

  return {
    candidature,
    userSelectedBranch,
    candidatureUuid,
    isLoading: isLoadingPostCandidature,
    hasError: isErrorPostCandidature,
    errorPostCandidature,
    isSuccessPostCandidature,
    actions,
    candidatureSelectableBlocs,
    isLoadingModifyCandidatureJury,
    isSuccessModifyCandidatureJury,
    isErrorModifyCandidatureJury,
    candidaturesList,
    isConsultCandidaturesList,
    isLoadingAcceptCandidature,
    isSuccessAcceptCandidature,
    isErrorAcceptCandidature,
    isLoadingRejectCandidature,
    isSuccessRejectCandidature,
    isErrorRejectCandidature,
    isLoadingDesinscrireCandidature,
    generalConditionnalDisplayVariables,
  };
}

export default useCandidature;

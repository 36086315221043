import { Stack, Text } from "@fluentui/react";
import { useNavigate } from "react-router-dom";
import useTheme from "../../../Common/useTheme";
import infoCardStyles from "./InfoCard.styles";

interface InfoCardProps {
  libelle: string;
  value: number;
  linkTo: string;
}

function InfoCard({ libelle, value, linkTo }: InfoCardProps) {
  const navigate = useNavigate();
  const { primaryColor } = useTheme();

  return (
    <Stack
      tokens={{ childrenGap: 5 }}
      onClick={() => navigate(linkTo)}
      className="ms-Grid-col ms-sm5 ms-md3 ms-lg2"
      style={{
        outline: `1px solid ${primaryColor}`,
        padding: "10px 10px 15px 10px",
        cursor: "pointer",
      }}
      styles={infoCardStyles}
    >
      <Stack.Item>
        <Text style={{ color: primaryColor, fontWeight: "bold", fontSize: 18 }}>
          {value}
        </Text>
      </Stack.Item>
      <Stack.Item>
        <Text>{libelle}</Text>
      </Stack.Item>
    </Stack>
  );
}

export default InfoCard;

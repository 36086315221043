import { Dropdown, Label, Stack, TextField, Toggle } from "@fluentui/react";
import { useContext, useEffect } from "react";
import { Controller } from "react-hook-form";
import FileInput from "../../../../Common/FileInput/FileInput";
import FormSection from "../../../../Common/FormSection/FormSection";
import { branchAccessEnum } from "../../../../config/accessEnum";
import useAccessSettings from "../../../../hooks/branchSettings/useAccessSettings";
import useConstants from "../../../../hooks/useConstants";
import { commonInputStyles } from "../../../../styles";
import { InformationsCandidatureContext } from "../useSaisieInformations";
import ErrorMessage from "../../../../Common/SelectField/common/ErrorMessage/ErrorMessage";
import useLabelSettings from "../../../LabelSettingsDetail/useLabelSettings";
import { useAtom } from "jotai";
import { userSelectedBranchAtom } from "../../../../atoms/branchAtom";
import { useQuery } from "react-query";
import { getCertificationsRequest } from "../../../../services/certificationServices";
import Loader from "../../../../Common/Loader/Loader";
import tools from "../../../../utils/tools";

function GeneralInformations({ inputsDisabled, enabledInputs = [] }: any) {
  const { labels } = useLabelSettings();
  const [userSelectedBranch] = useAtom(userSelectedBranchAtom);
  const { constants } = useConstants();
  const { actions: accessActions } = useAccessSettings();

  const { conditionnalDisplayVariables, reactHookProps, candidature }: any =
    useContext(InformationsCandidatureContext);
  const {
    needExtraLibelleFinancement,
    needExtraLibelleTypeContrat,
    positionneurExist,
  } = conditionnalDisplayVariables;
  const {
    control,
    setValue,
    watch,
    clearErrors,
    formState: { errors },
  } = reactHookProps;

  useEffect(() => {
    //si sans diplome sélectionné, par d'attestation à fournir
    if (watch("niveau_dernier_diplome")?.id === 1) {
      setValue("attestation_dernier_diplome", null);
      setValue("attestation_dernier_diplome_url", "");
      setValue("attestation_dernier_diplome_deleted", true);
      setValue("intitule_dernier_diplome", "");
    }
  }, [watch("niveau_dernier_diplome")?.id]);

  // useEffect(() => {
  //   //si en recherche d'emploi sélectionné, par de contrat
  //   if (watch("situation_avant_cursus")?.id === 2) {
  //     setValue(
  //       "type_contrat",
  //       tools.findIn(constants["CANDIDATURES"]["TYPES_CONTRAT"], 13, "id")
  //     );
  //   }
  // }, [watch("situation_avant_cursus")?.id]);

  const needVoieAccesVAE =
    (reactHookProps.watch("modalite_certification") as any)?.id ==
    (constants["CERTIFICATIONS"]["CANDIDATURE_TYPES"] as any)?.find(
      (m: any) => m.text == "VAE"
    ).id;

  const { data: certifications, isLoading: isLoadingCertifications } = useQuery(
    [
      "certifications_candidature",
      userSelectedBranch,
      reactHookProps.watch("domaine"),
    ],
    () => {
      return getCertificationsRequest(
        userSelectedBranch.gestion_par_domaine
          ? {
              domaines: [reactHookProps.watch("domaine")],
              active: { key: "yes", text: "Oui", value: true },
            }
          : {
              active: { key: "yes", text: "Oui", value: true },
            },
        {
          sort: "libelle",
          page: 1,
          paginate: false,
          searchAt: "",
        },
        userSelectedBranch.id
      );
    },
    {
      enabled: userSelectedBranch.gestion_par_domaine
        ? !!watch("domaine")?.id
        : true,
      refetchOnWindowFocus: false,
      retry: 0,
    }
  );
  const typeContratAllowed = tools.typeContratAllowed(
    reactHookProps.watch("certification")?.type,
    constants["CANDIDATURES"]["TYPES_CONTRAT"]
  );

  return isLoadingCertifications ? (
    <Loader />
  ) : (
    <FormSection sectionTitle="Informations de la candidature">
      <Stack.Item>
        <Controller
          render={({ field: { name, value, onBlur, ref } }) => {
            return (
              <Dropdown
                label="Certification (sélection unique) :"
                selectedKey={value ? (value as any).key : undefined}
                placeholder="Sélectionnez une certification"
                options={certifications?.data.map((certification: any) => ({
                  ...certification,
                  key: certification.uuid,
                  text: `${certification.libelle} - ${certification.type_libelle}`,
                }))}
                onChange={(e: any, options: any) => {
                  setValue(name, options, { shouldValidate: true });
                }}
                styles={commonInputStyles.dropdownStylePrefilled}
                required
                disabled={inputsDisabled}
                errorMessage={(errors[name] as any)?.message}
              />
            );
          }}
          control={control}
          name="certification"
        />
      </Stack.Item>
      {/* a modifier -> INPUT prerempli */}
      <Stack.Item>
        <Controller
          render={({ field: { name, value } }) => (
            <Dropdown
              required
              label="Modalité d'accès à la certification :"
              selectedKey={value ? (value as any).key : undefined}
              placeholder="Sélectionnez la modalité"
              options={
                constants["CANDIDATURES"]["MODALITES_ACCES_CERTIFICATION"]
              }
              onChange={(e: any, options: any) => {
                setValue(name, options, { shouldValidate: true });
              }}
              styles={commonInputStyles.dropdownStylePrefilled}
              disabled={inputsDisabled && !enabledInputs.includes(name)}
              errorMessage={(errors[name] as any)?.message}
            />
          )}
          control={control}
          name="modalite_certification"
        />
      </Stack.Item>

      {needVoieAccesVAE && (
        <Stack.Item>
          <Controller
            render={({ field: { name, value } }) => (
              <Dropdown
                label="Voie d'accès à la VAE :"
                selectedKey={value ? (value as any).key : undefined}
                placeholder="Sélectionnez la voie"
                options={constants["CANDIDATURES"]["VOIE_ACCES_VAE"]}
                onChange={(e: any, options: any) => {
                  setValue(name, options, { shouldValidate: true });
                }}
                styles={commonInputStyles.dropdownStylePrefilled}
                required
                disabled={inputsDisabled && !enabledInputs.includes(name)}
                errorMessage={(errors[name] as any)?.message}
              />
            )}
            control={control}
            name="voie_acces_vae"
          />
        </Stack.Item>
      )}

      {/* a modifier -> INPUT prerempli */}
      <Stack.Item>
        <Controller
          render={({ field: { name, value } }) => (
            <Dropdown
              required
              label="Dispositif de financement :"
              selectedKey={value ? (value as any).key : undefined}
              placeholder="Sélectionnez le dispositif de financement"
              options={constants["CANDIDATURES"]["DISPOSITIFS_FINANCEMENT"]}
              onChange={(e: any, options: any) => {
                setValue(name, options, { shouldValidate: true });
              }}
              styles={commonInputStyles.dropdownStylePrefilled}
              disabled={inputsDisabled && !enabledInputs.includes(name)}
              errorMessage={(errors[name] as any)?.message}
            />
          )}
          control={control}
          name="dispositif_financement"
        />
      </Stack.Item>

      {/* a modifier -> INPUT prerempli */}
      {needExtraLibelleFinancement && (
        <Stack.Item>
          <Controller
            render={({ field }) => (
              <TextField
                styles={commonInputStyles.textFieldPrefilled}
                label="Libellé du dispositif de financement :"
                type="text"
                {...field}
                placeholder="Ex : Mon libellé"
                errorMessage={(errors[field.name] as any)?.message}
                required
                disabled={inputsDisabled && !enabledInputs.includes(field.name)}
              />
            )}
            control={control}
            name="dispositif_financement_libelle"
          />
        </Stack.Item>
      )}

      {/* a modifier -> INPUT prerempli */}
      <Stack.Item>
        <Controller
          render={({ field: { name, value } }) => (
            <Dropdown
              required
              label="Type de contrat :"
              selectedKey={value ? (value as any).key : undefined}
              placeholder="Sélectionnez le type de contrat"
              options={typeContratAllowed}
              onChange={(e: any, options: any) => {
                setValue(name, options, { shouldValidate: true });
              }}
              styles={commonInputStyles.dropdownStylePrefilled}
              disabled={inputsDisabled && !enabledInputs.includes(name)}
              errorMessage={(errors[name] as any)?.message}
            />
          )}
          control={control}
          name="type_contrat"
        />
      </Stack.Item>

      {/* a modifier -> INPUT prerempli */}
      {needExtraLibelleTypeContrat && (
        <Stack.Item>
          <Controller
            render={({ field }) => (
              <TextField
                styles={{ ...commonInputStyles.textFieldPrefilled }}
                label="Libellé du type de contrat :"
                type="text"
                {...field}
                placeholder="Ex : Mon libellé"
                errorMessage={(errors[field.name] as any)?.message}
                required
                disabled={inputsDisabled && !enabledInputs.includes(field.name)}
              />
            )}
            control={control}
            name="type_contrat_libelle"
          />
        </Stack.Item>
      )}

      <Stack.Item>
        <Controller
          render={({ field: { name, value } }) => (
            <Dropdown
              required
              label="Situation avant le cursus certifiant ou à vocation certifiante :"
              selectedKey={value ? (value as any).key : undefined}
              placeholder="Sélectionnez la situation"
              options={constants["CANDIDATURES"]["SITUATIONS_AVANT_CURSUS"]}
              onChange={(e: any, options: any) => {
                setValue(name, options, { shouldValidate: true });
              }}
              styles={commonInputStyles.dropdownStyle}
              disabled={inputsDisabled && !enabledInputs.includes(name)}
              errorMessage={(errors[name] as any)?.message}
            />
          )}
          control={control}
          name="situation_avant_cursus"
        />
      </Stack.Item>

      <Stack.Item>
        <Controller
          render={({ field }) => (
            <TextField
              styles={commonInputStyles.textField}
              label="Dernier métier exercé :"
              type="text"
              {...field}
              placeholder="Ex : Électricien"
              errorMessage={(errors[field.name] as any)?.message}
              required
              disabled={inputsDisabled && !enabledInputs.includes(field.name)}
            />
          )}
          control={control}
          name="dernier_metier"
        />
      </Stack.Item>

      <Stack.Item>
        <Controller
          render={({ field }) => (
            <TextField
              styles={commonInputStyles.textField}
              label="Années totales d’expérience dans le dernier métier exercé :"
              type="text"
              {...field}
              placeholder="Ex : 2"
              errorMessage={(errors[field.name] as any)?.message}
              required
              disabled={inputsDisabled && !enabledInputs.includes(field.name)}
            />
          )}
          control={control}
          name="annees_experience"
        />
      </Stack.Item>

      <Stack.Item>
        <Controller
          render={({ field: { name, value } }) => (
            <Dropdown
              required
              label="Niveau du dernier diplôme obtenu :"
              selectedKey={value ? (value as any).key : undefined}
              placeholder="Sélectionnez le niveau du dernier diplôme"
              options={constants["CANDIDATURES"]["NIVEAUX_DIPLOME"]}
              onChange={(e: any, options: any) => {
                setValue(name, options, { shouldValidate: true });
              }}
              styles={commonInputStyles.dropdownStyle}
              disabled={inputsDisabled && !enabledInputs.includes(name)}
              errorMessage={(errors[name] as any)?.message}
            />
          )}
          control={control}
          name="niveau_dernier_diplome"
        />
      </Stack.Item>

      {watch("niveau_dernier_diplome")?.id != 1 && (
        <Stack.Item>
          <Controller
            render={({ field }) => (
              <TextField
                styles={commonInputStyles.textField}
                label="Intitulé du dernier diplôme obtenu :"
                type="text"
                {...field}
                placeholder="Ex : Mon dernier diplôme"
                errorMessage={(errors[field.name] as any)?.message}
                required
                disabled={inputsDisabled && !enabledInputs.includes(field.name)}
              />
            )}
            control={control}
            name="intitule_dernier_diplome"
          />
        </Stack.Item>
      )}

      {watch("niveau_dernier_diplome")?.id != 1 && (
        <Stack.Item>
          <Label
            styles={commonInputStyles.label}
            required={
              accessActions.canI({
                action: branchAccessEnum.OBLIGER_UPLOAD_DERNIER_DIPLOME,
              }).granted
            }
          >
            Attestation du dernier diplôme obtenu :
          </Label>
          <FileInput
            name="attestation_dernier_diplome"
            file={
              watch("attestation_dernier_diplome")
                ? [watch("attestation_dernier_diplome")]
                : []
            }
            onUpdateFile={(newFile: any) => {
              if (newFile.target.value.length > 0) {
                setValue("attestation_dernier_diplome_deleted", false);
              }
              setValue("attestation_dernier_diplome", newFile.target.value[0]);
              clearErrors("attestation_dernier_diplome");
              //actions.onUpdateParcheminFile(newFile, null);
            }}
            onDeleteFile={() => {
              setValue("attestation_dernier_diplome_url", "");
              setValue("attestation_dernier_diplome_deleted", true);
            }}
            existingFileURL={watch("attestation_dernier_diplome_url")}
            acceptedFileTypes={["PDF", "PNG", "JPG", "JPEG"]}
            disabled={
              inputsDisabled &&
              !enabledInputs.includes("attestation_dernier_diplome")
            }
          />
        </Stack.Item>
      )}

      {(accessActions.canI({
        action: branchAccessEnum.OBLIGER_POSITIONNEMENT,
      }).granted ||
        accessActions.canI({
          action:
            branchAccessEnum.AUTORISER_CREATEUR_CANDIDATURE_DEPOSER_POSITIONNEMENT,
        }).granted) && (
        <Stack.Item>
          <Controller
            render={({ field: { onChange, value, name } }) => (
              <>
                <Label
                  htmlFor="positionnement_par_candidat"
                  required
                  styles={commonInputStyles.label}
                >
                  {labels.CANDIDATURE_CREE_QUESTIONPOSITIONNEMENT}
                </Label>
                <Toggle
                  styles={commonInputStyles.toggleInput}
                  onText="Oui"
                  offText="Non"
                  onChange={(e, checked) => onChange(checked)}
                  checked={
                    positionneurExist &&
                    accessActions.canI({
                      action: branchAccessEnum.OBLIGER_POSITIONNEMENT,
                    }).granted
                      ? true
                      : value
                  }
                  id="positionnement_par_candidat"
                  disabled={
                    inputsDisabled ||
                    !positionneurExist ||
                    (positionneurExist &&
                      accessActions.canI({
                        action: branchAccessEnum.OBLIGER_POSITIONNEMENT,
                      }).granted)
                  }
                />
                <ErrorMessage errorMessage={(errors[name] as any)?.message} />
              </>
            )}
            defaultValue={false}
            control={control}
            name="positionnement_par_candidat"
          />
        </Stack.Item>
      )}
    </FormSection>
  );
}

export default GeneralInformations;

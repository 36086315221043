import {
  Checkbox,
  DefaultButton,
  DetailsList,
  DetailsListLayoutMode,
  SelectionMode,
  Stack,
  Text,
  TextField,
} from "@fluentui/react";
import LoadLink from "../../../Common/LoadLink/LoadLink";
import InfoMessage from "../../../Common/SelectField/common/InfoMessage/InfoMessage";
import { commonButtonStyles, commonInputStyles } from "../../../styles";
import useCritereDynamicList from "./useCritereDynamicList";

function CritereDynamicList({
  label, //label global de l'input
  placeholder, //placeholder du champ de recherche
  addButtonLabel,
  errorMessage, //message d'erreur de l'input
  onChange = () => {}, //appelé lorsque la valeur sélectionnée change
  onBlur = () => {},
  value, //objet ex : {name:""}, valeur de l'input
  name, //nom de l'input
  fieldRef,
  required, //si requis
  defaultValue,
  attr,
  validateItem,
  disabled = false,
}: any) {
  const { terms, errors, isRemovingCritere, actions, items } =
    useCritereDynamicList({
      attr,
      value,
      defaultValue,
      name,
      validateItem,
      onChange,
    });

  const columns = [
    {
      key: "1",
      name: "Nom du critère",
      fieldName: "critere",
      minWidth: 150,
      maxWidth: 800,
      isMultiline: true,
      onRender: (item: any) => <Text>{item.libelle}</Text>,
    },
    {
      key: "2",
      name: "Actif",
      fieldName: "actif",
      minWidth: 50,
      maxWidth: 150,
      onRender: (item: any, index: any) => (
        <Stack horizontal>
          <Checkbox
            key={item.libelle}
            checked={item.actif}
            onChange={(e: any, checked: any) => {
              actions.onUpdateStatutItem(index, checked);
            }}
          />
          <Text>{item.actif ? "Oui" : "Non"}</Text>
        </Stack>
      ),
    },
    {
      key: "3",
      name: "Actions",
      fieldName: "actions",
      minWidth: 100,
      onRender: (item: any, index: any) => {
        return (
          <Stack>
            <LoadLink
              styles={commonButtonStyles.disableButtonLink}
              onClick={() => actions.onRemoveItem(index)}
              text={"Supprimer"}
            />
          </Stack>
        );
      },
    },
  ];

  return (
    <Stack tokens={{ childrenGap: 10 }}>
      <Stack.Item>
        <TextField
          required={required}
          styles={commonInputStyles.textField}
          placeholder={placeholder}
          onChange={actions.handleChangeTerms}
          value={terms}
          type="text"
          onKeyDown={actions.onKeyDown}
          label={label}
          onBlur={onBlur}
          errorMessage={errorMessage || errors[attr]}
          componentRef={fieldRef}
          name={attr}
          disabled={disabled}
        />
      </Stack.Item>

      <Stack.Item style={{ display: "flex", justifyContent: "end" }}>
        <DefaultButton
          type="button"
          text={addButtonLabel}
          iconProps={{ iconName: "Add" }}
          onClick={actions.onAddTerm}
          disabled={disabled}
        />
      </Stack.Item>

      <DetailsList
        layoutMode={DetailsListLayoutMode.justified}
        items={items}
        columns={columns}
        selectionMode={SelectionMode.none}
      />

      {isRemovingCritere && (
        <Stack.Item>
          <InfoMessage
            infoMessage={
              "ATTENTION : pensez à enregistrer vos modifications pour qu’elles prennent effet"
            }
          />
        </Stack.Item>
      )}
    </Stack>
  );
}

export default CritereDynamicList;

import {
  ActionButton,
  DefaultButton,
  DetailsList,
  DetailsListLayoutMode,
  IColumn,
  IIconProps,
  SelectionMode,
  Text,
  TextField,
} from "@fluentui/react";
import { atom, useAtom } from "jotai";
import { useContext, useEffect } from "react";
import { axiosInstance } from "../../../config/axiosConfig";
import { commonInputStyles } from "../../../styles";
import { CertificationContext } from "../CertificationFormContext/CertificationFormContext";

const eCertifApi = axiosInstance;

const searchAtom = atom("");
//pourquoi un ATOM ?
const foundBlocksAtom = atom([]);

var searchTimeout: any = null;
const addIcon: IIconProps = { iconName: "Add" };
const removeIcon: IIconProps = { iconName: "Cancel" };

const AddBlocsForm = ({
  selectedBlocks = [],
  forOption,
  method = "GET",
  resourceURI,
  addQueryURL = (resourceURI: any) => {
    return resourceURI;
  },
  handleResponse = () => {},
}: any) => {
  const { actions, conditionnalDisplayVariables }: any =
    useContext(CertificationContext);
  const { queryUrlSearchBlockInterBranch } = conditionnalDisplayVariables;
  const { onAddBlock, onRemoveBlock } = actions;
  const [search, setSearch] = useAtom(searchAtom);
  const [foundBlocks, setFoundBlocks] = useAtom(foundBlocksAtom);

  useEffect(() => {
    setSearch("");
    setFoundBlocks([]);
  }, [queryUrlSearchBlockInterBranch]);

  const onKeyDown = (e: any) => {
    if (e.keyCode === 13) {
      e.preventDefault();
      e.stopPropagation();
      handleChangeSearch(e);
    }
  };

  const handleChangeSearch = (e: any) => {
    const { value } = e.target;
    setSearch(value);
    clearTimeout(searchTimeout);

    if (value === "") {
      setFoundBlocks([]);
      return false;
    }
    searchTimeout = setTimeout(() => {
      getCompetenceBlocks(value);
    }, 1000);
  };

  const getCompetenceBlocks = async (terms: string) => {
    const response = await eCertifApi({
      method,
      url: addQueryURL(resourceURI, terms),
    });
    setFoundBlocks(handleResponse(response, terms));
    //fake data, cabler avec l'api plus tard avec une vraie requête
    // a cabler ici
  };

  const handleAddBlock = (item: any, forOption: any) => {
    onAddBlock(item, forOption);
    setSearch("");
    setFoundBlocks([]);
  };

  const columns: IColumn[] = [
    {
      key: "1",
      name: "Libellé",
      fieldName: "text",
      minWidth: 200,
      maxWidth: 300,
      isResizable: true,
      onRender: (item: any) => (
        <Text>
          {item.reference && item.reference + " - "}
          {item.libelle}
        </Text>
      ),
    },

    {
      key: "2",
      name: "Certifications rattachées",
      fieldName: "certifications",
      minWidth: 200,
      maxWidth: 300,
      isResizable: true,
      isCollapsible: true,

      onRender: (item: any) =>
        item.certifications.map((certif: any) => (
          <div>
            {certif.libelle}
            <span className="text-muted">
              {" "}
              - {certif.branche_creatrice?.nom}
            </span>
          </div>
        )),
    },
    {
      key: "3",
      name: "Branche créatrice",
      fieldName: "branch",
      minWidth: 200,
      maxWidth: 300,
      isResizable: true,
      isCollapsible: true,

      onRender: (item: any) => <Text>{item.branche_creatrice.nom}</Text>,
    },
    {
      key: "4",
      name: "Ajouter",
      fieldName: "uuid",
      minWidth: 200,
      maxWidth: 300,
      isResizable: true,
      isCollapsible: true,

      onRender: (item: any) =>
        selectedBlocks.find((b: any) => {
          return b.uuid == item.uuid;
        }) ? (
          <div>
            {/* <div className="">
              <Icon iconName="CheckMark" style={{ paddingRight: 5 }} />
              <Text className="text-green">ajouté</Text>
            </div> */}
            <ActionButton
              iconProps={removeIcon}
              text="Retirer ce bloc"
              onClick={() => onRemoveBlock(item, forOption)}
            />
          </div>
        ) : (
          <DefaultButton
            text="Ajouter ce bloc"
            iconProps={addIcon}
            onClick={() => handleAddBlock(item, forOption)}
            allowDisabledFocus
          />
        ),
    },
  ];

  return (
    <div>
      <TextField
        styles={commonInputStyles.textField}
        label="Blocs de compétences de la certification :"
        type="text"
        name="search"
        placeholder="Recherchez un bloc de compétences en tapant les premières lettres de son libellé ou de sa branche"
        value={search}
        onChange={handleChangeSearch}
        onKeyDown={onKeyDown}
      />
      <DetailsList
        layoutMode={DetailsListLayoutMode.justified}
        items={foundBlocks}
        columns={columns}
        selectionMode={SelectionMode.none}
        styles={{
          root: {
            selectors: {
              ".ms-DetailsHeader": {
                backgroundColor: "rgba(255,255,255,0)",
              },
              ".ms-DetailsRow": {
                backgroundColor: "rgba(255,255,255,0)",
              },
            },
          },
        }}
      />
    </div>
  );
};

export default AddBlocsForm;

import {
  Checkbox,
  DetailsList,
  DetailsListLayoutMode,
  Stack,
  Text,
} from "@fluentui/react";
import { Controller } from "react-hook-form";
import { RiAddFill } from "react-icons/ri";
import BackLink from "../../Common/BackLink/BackLink";
import CancelButton from "../../Common/CancelButton/CancelButton";
import LoadButton from "../../Common/LoadButton/LoadButton";
import Loader from "../../Common/Loader/Loader";
import Page from "../../Common/Page/Page";
import Tooltip from "../../Common/Tooltip/Tooltip";
import useAccessSettings from "../../hooks/branchSettings/useAccessSettings";
import useConstants from "../../hooks/useConstants";
import tools from "../../utils/tools";
import useRolesManagement from "../RolesManagement/useRolesManagement";

const checkboxStyle = {
  checkbox: {
    border: "1px solid #D1D1D1",
  },
  root: {
    justifyContent: "center",
    selectors: {
      ".ms-Checkbox-text": {
        fontSize: "16px",
        fontWeight: "500",
      },
    },
  },
};

enum HabilitationParamBranche {
  HABILITATION_STRICTE_POSITIONNEUR = "HABILITATION_STRICTE_POSITIONNEUR",
  HABILITATION_STRICTE_EVALUATEUR = "HABILITATION_STRICTE_EVALUATEUR",
}

function BranchSettingsDetail() {
  const {
    branchSettings,
    reactHookProps,
    isLoadingBranchSettings,
    actions,
  }: any = useAccessSettings();
  const { branchRolesSettings } = useRolesManagement();
  const { constants } = useConstants();
  const { clearErrors, handleSubmit, control } = reactHookProps;

  const onSubmit = (e: any) => {
    clearErrors();
    handleSubmit(actions.saveCurrentBranchSettings)(e);
  };

  const listHabilitationParamBranche = Object.values(HabilitationParamBranche);

  if (!branchSettings) return null;

  const columns = [
    {
      key: "branch_settings",
      name: "Paramètre",
      minWidth: 100,
      onRender: (item: any) => {
        const whichProfilImpact = Array.from(
          new Set([...item.impacts_matrice].map((impact) => impact.profil))
        );
        const contentImpactMatrice = (
          <Stack>
            {whichProfilImpact.map((profil: any) => (
              <>
                <Text>
                  La modification de ce paramètre impacte le(s) permission(s) du
                  profil{" "}
                  {
                    (
                      tools.findIn(
                        constants.UTILISATEURS.PROFILS,
                        profil,
                        "id"
                      ) as any
                    ).text
                  }{" "}
                  :
                </Text>

                <ul>
                  {[...item.impacts_matrice]
                    ?.filter((impact) => impact.profil == profil)
                    .map((impact: any) => (
                      <li>
                        {
                          (
                            tools.findIn(
                              branchRolesSettings,
                              impact.acces,
                              "acces"
                            ) as any
                          ).acces_label
                        }
                      </li>
                    ))}
                </ul>
              </>
            ))}
          </Stack>
        );

        const contentDependDe = (
          <Stack>
            <Text>Ce paramètre dépend du(des) paramètre(s) :</Text>
            <ul>
              {item.depend_de?.map((param: any) => (
                <li>
                  {
                    (tools.findIn(branchSettings, param, "parametre") as any)
                      .label
                  }
                </li>
              ))}
            </ul>
          </Stack>
        );

        return (
          <div style={{ display: "flex", alignItems: "center" }}>
            {item.branch_settings}
            {item.impacts_matrice?.length > 0 && (
              <Tooltip
                id={item.id + "impact_matrice"}
                content={contentImpactMatrice}
                iconName={"UserWarning"}
                colors={{
                  classic: "#D40000",
                  hovered: "#960000",
                }}
              />
            )}
            {item.depend_de?.length > 0 && (
              <Tooltip
                id={item.id + "depend_de"}
                iconName={"Warning"}
                content={contentDependDe}
                colors={{
                  classic: "#D40000",
                  hovered: "#960000",
                }}
              />
            )}
          </div>
        );
      },
      isMultiline: true,
    },
    {
      key: "action",
      name: "Action",
      minWidth: 100,
      onRender: (item: any) => {
        const isHabilitationParamBranche =
          process.env.REACT_APP_MODE == "production" &&
          listHabilitationParamBranche.includes(item.key);

        return (
          <Controller
            defaultValue={item.value}
            name={item.key + ""}
            control={control}
            render={({ field: { onChange, value, name } }) => (
              <Checkbox
                styles={checkboxStyle}
                checked={value}
                onChange={onChange}
                name={name}
                disabled={isHabilitationParamBranche}
              />
            )}
          />
        );
      },
    },
  ];

  const data: any = branchSettings.map((setting: any) => {
    return {
      key: setting.parametre,
      branch_settings: setting.label,
      value: setting.valeur,
      categorie: setting.categorie,
      impacts_matrice: setting.impacts_matrice,
      depend_de: setting.depend_de,
    };
  });

  const dataGroupByCategorie = (Object as any).groupBy(
    data,
    ({ categorie }: any) => categorie
  ); //{categorie1:[], categorie2:[], ...}
  const datasSort = Object.keys(dataGroupByCategorie)
    .sort() //string[] de categorie sort
    .map((categorie: string) =>
      dataGroupByCategorie[categorie].sort(
        (accessA: any, accessB: any) =>
          accessA.branch_settings.split(" ")[0].split(".")[1] -
          accessB.branch_settings.split(" ")[0].split(".")[1]
      )
    );

  return (
    <Stack>
      <BackLink />

      <Page
        title={"Paramètres de la branche"}
        explain={"Veuillez renseigner les paramètres de la branche"}
      >
        {isLoadingBranchSettings && <Loader />}

        <form onSubmit={onSubmit}>
          {datasSort.map((dataByCategorie: any) => (
            <>
              <h2>{dataByCategorie[0].categorie}</h2>
              <DetailsList
                layoutMode={DetailsListLayoutMode.justified}
                items={dataByCategorie}
                columns={columns}
                selectionMode={0}
              />
            </>
          ))}

          <Stack
            horizontal
            horizontalAlign="space-between"
            style={{ marginTop: 20 }}
          >
            <CancelButton />

            <LoadButton
              text={"Enregistrer les paramètres"}
              isLoading={isLoadingBranchSettings}
              icon={<RiAddFill size={38} />}
              style={{ fontWeight: "bold" }}
            />
          </Stack>
        </form>
      </Page>
    </Stack>
  );
}

export default BranchSettingsDetail;

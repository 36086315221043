import FormSection from "../../../../Common/FormSection/FormSection";
import { Controller } from "react-hook-form";
import { Dropdown, MessageBar, Stack, TextField } from "@fluentui/react";
import { useContext } from "react";
import { CandidatureContext } from "../useCreationCandidature";
import { MessageBarStyles, commonInputStyles } from "../../../../styles";
import useConstants from "../../../../hooks/useConstants";
import useLabelSettings from "../../../LabelSettingsDetail/useLabelSettings";
import { useAtom } from "jotai";
import { userSelectedBranchAtom } from "../../../../atoms/branchAtom";
import { useQuery } from "react-query";
import { getCertificationsRequest } from "../../../../services/certificationServices";
import Loader from "../../../../Common/Loader/Loader";
import useAccessSettings from "../../../../hooks/branchSettings/useAccessSettings";
import { branchAccessEnum } from "../../../../config/accessEnum";
import tools from "../../../../utils/tools";
import useAuth from "../../../../hooks/useAuth";

//  CQP = 1, CQPI = 2, CQP_DELIVRANT_CQPI = 3,
export const certificationTypeCantBeContratApprentissageValues = [1, 2, 3];

function CertificationSection({ disabled, enabledInputs = [] }: any) {
  const [userSelectedBranch] = useAtom(userSelectedBranchAtom);
  const { reactHookProps, conditionnalDisplayVariables }: any =
    useContext(CandidatureContext);
  const { needExtraLibelleFinancement, needExtraLibelleTypeContrat } =
    conditionnalDisplayVariables;
  const { setValue, control } = reactHookProps;
  const { constants } = useConstants();
  const { auth } = useAuth();
  const { labels } = useLabelSettings();
  const { actions: accessActions } = useAccessSettings();

  const {
    formState: { errors },
  } = reactHookProps;

  const needVoieAccesVAE =
    (reactHookProps.watch("modalite_certification") as any)?.id ==
    (constants["CERTIFICATIONS"]["CANDIDATURE_TYPES"] as any)?.find(
      (m: any) => m.text == "VAE"
    ).id;

  const { data: certifications, isLoading: isLoadingCertifications } = useQuery(
    [
      "certifications_candidature",
      userSelectedBranch,
      reactHookProps.watch("domaine"),
    ],
    () => {
      return getCertificationsRequest(
        userSelectedBranch.gestion_par_domaine
          ? {
              domaines: [reactHookProps.watch("domaine")],
              active: { key: "yes", text: "Oui", value: true },
            }
          : {
              active: { key: "yes", text: "Oui", value: true },
            },
        {
          sort: "libelle",
          page: 1,
          paginate: false,
          searchAt: "",
        },
        userSelectedBranch.id
      );
    },
    { retry: 0, refetchOnWindowFocus: false }
  );

  const certificationsList = certifications?.data.map((certification: any) => ({
    ...certification,
    key: certification.uuid,
    text: `${certification.libelle} - ${certification.type_libelle}`,
  }));

  const haveNotHabilitationToCertification =
    certificationsList?.length == 0 &&
    accessActions.canI({
      action: branchAccessEnum.HABILITATION_SIMPLE_OF,
    }).granted &&
    (auth.user as any)?.profils.filter((p: any) => p.profil != "OF").length ==
      0;
  const typeContratAllowed = tools.typeContratAllowed(
    reactHookProps.watch("certification")?.type,
    constants["CANDIDATURES"]["TYPES_CONTRAT"]
  );

  return isLoadingCertifications ? (
    <Loader />
  ) : (
    <FormSection sectionTitle={labels.CANDIDATURE_CANDIDAT_CERTIFICATION}>
      <Stack.Item>
        <Controller
          render={({ field: { name, value, onBlur, ref } }) => {
            return (
              <Dropdown
                label="Certification (sélection unique) :"
                selectedKey={value ? (value as any).key : undefined}
                placeholder="Sélectionnez une certification"
                options={certificationsList}
                onChange={(e: any, options: any) => {
                  setValue(name, options, { shouldValidate: true });
                }}
                styles={commonInputStyles.dropdownStyle}
                required
                disabled={
                  (disabled && !enabledInputs.includes(name)) ||
                  haveNotHabilitationToCertification
                }
                errorMessage={(errors[name] as any)?.message}
              />
            );
          }}
          control={control}
          name="certification"
        />
      </Stack.Item>
      {haveNotHabilitationToCertification && (
        <div style={{ margin: "15px 0" }}>
          <MessageBar className="bg-info" styles={MessageBarStyles}>
            Votre OF n'est habilité sur aucune certification.
          </MessageBar>
        </div>
      )}
      <Stack.Item>
        <Controller
          render={({ field: { name, value } }) => (
            <Dropdown
              label="Modalité d'accès à la certification :"
              selectedKey={value ? (value as any).key : undefined}
              placeholder="Sélectionnez la modalité"
              options={constants["CERTIFICATIONS"]["CANDIDATURE_TYPES"]}
              onChange={(e: any, options: any) => {
                setValue(name, options, { shouldValidate: true });
              }}
              styles={commonInputStyles.dropdownStyle}
              required
              disabled={disabled && !enabledInputs.includes(name)}
              errorMessage={(errors[name] as any)?.message}
            />
          )}
          control={control}
          name="modalite_certification"
        />
      </Stack.Item>

      {needVoieAccesVAE && (
        <Stack.Item>
          <Controller
            render={({ field: { name, value } }) => (
              <Dropdown
                label="Voie d'accès à la VAE :"
                selectedKey={value ? (value as any).key : undefined}
                placeholder="Sélectionnez la voie"
                options={constants["CANDIDATURES"]["VOIE_ACCES_VAE"]}
                onChange={(e: any, options: any) => {
                  setValue(name, options, { shouldValidate: true });
                }}
                styles={commonInputStyles.dropdownStyle}
                required
                disabled={disabled && !enabledInputs.includes(name)}
                errorMessage={(errors[name] as any)?.message}
              />
            )}
            control={control}
            name="voie_acces_vae"
          />
        </Stack.Item>
      )}

      <Stack.Item>
        <Controller
          render={({ field: { name, value } }) => (
            <Dropdown
              label="Dispositif de financement :"
              selectedKey={value ? (value as any).key : undefined}
              placeholder="Sélectionnez le dispositif de financement"
              options={constants["CANDIDATURES"]["DISPOSITIFS_FINANCEMENT"]}
              onChange={(e: any, options: any) => {
                setValue(name, options, { shouldValidate: true });
              }}
              styles={commonInputStyles.dropdownStyle}
              disabled={disabled && !enabledInputs.includes(name)}
              required
              errorMessage={(errors[name] as any)?.message}
            />
          )}
          control={control}
          name="dispositif_financement"
        />
      </Stack.Item>

      {needExtraLibelleFinancement && (
        <Stack.Item>
          <Controller
            render={({ field }) => (
              <TextField
                styles={commonInputStyles.textField}
                label="Libellé du dispositif de financement :"
                type="text"
                {...field}
                placeholder="Ex : Mon libellé"
                //errorMessage={(errors[field.name] as any)?.message}
                required
                disabled={disabled && !enabledInputs.includes(field.name)}
                errorMessage={(errors[field.name] as any)?.message}
              />
            )}
            control={control}
            name="dispositif_financement_libelle"
          />
        </Stack.Item>
      )}

      <Stack.Item>
        <Controller
          render={({ field: { name, value } }) => (
            <Dropdown
              label="Type de contrat :"
              selectedKey={value ? (value as any).key : undefined}
              placeholder="Sélectionnez le type de contrat"
              options={typeContratAllowed}
              onChange={(e: any, options: any) => {
                setValue(name, options, { shouldValidate: true });
              }}
              styles={commonInputStyles.dropdownStyle}
              required
              disabled={disabled && !enabledInputs.includes(name)}
              errorMessage={(errors[name] as any)?.message}
            />
          )}
          control={control}
          name="type_contrat"
        />
      </Stack.Item>

      {needExtraLibelleTypeContrat && (
        <Stack.Item>
          <Controller
            render={({ field }) => (
              <TextField
                styles={commonInputStyles.textField}
                label="Libellé du type de contrat :"
                type="text"
                {...field}
                placeholder="Ex : Mon libellé"
                //errorMessage={(errors[field.name] as any)?.message}
                required
                disabled={disabled && !enabledInputs.includes(field.name)}
                errorMessage={(errors[field.name] as any)?.message}
              />
            )}
            control={control}
            name="type_contrat_libelle"
          />
        </Stack.Item>
      )}
    </FormSection>
  );
}

export default CertificationSection;

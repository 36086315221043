import React, { useEffect, useState } from "react";
import {
  Checkbox,
  ChoiceGroup,
  DefaultButton,
  Label,
  MessageBar,
  Stack,
  TextField,
} from "@fluentui/react";
import { MessageBarStyles, commonButtonStyles } from "../../../../styles";
import useMiseEnConfirmite from "./useMiseEnConfirmite";
import useCandidature from "../../../../hooks/candidature/useCandidature";
import tools from "../../../../utils/tools";
import Badge from "../../../../Common/Badge/Badge";
import LoadButton from "../../../../Common/LoadButton/LoadButton";
import useCustomPanel from "../../../../Common/Overlays/CustomPanel/useCustomPanel";
import Loader from "../../../../Common/Loader/Loader";
import useTheme from "../../../../Common/useTheme";
import { MessageBarErrorStyles } from "../../../../styles/MessageBar.styles";

const checkboxStyle = {
  checkbox: {
    border: "1px solid #D1D1D1",
  },
  root: {
    justifyContent: "center",
    selectors: {
      ".ms-Checkbox-text": {
        fontSize: "16px",
        fontWeight: "500",
      },
    },
  },
};

const DialogDemanderMEC = ({
  onAfterValidate = () => {},
  createurUuid,
}: any) => {
  const { dangerColor } = useTheme();
  const [selectedRecipients, setSelectedRecipients]: any = useState([]);
  const { mecActions, isMECAsking, isLoadingMECRecipients, mecRecipients } =
    useMiseEnConfirmite();
  const { actions }: any = useCandidature();
  const [comment, setComment] = useState("");
  const { actions: panelActions } = useCustomPanel();
  const [errors, setErrors]: any = useState([]);

  useEffect(() => {
    mecActions.onGetMECRecipients();
  }, []);

  const onCheckRecipient = (recipient: any) => {
    setSelectedRecipients([recipient]);
    // if (selectedRecipients.find((r: any) => r.uuid == recipient.uuid)) {
    //   setSelectedRecipients((s: any) =>
    //     s.filter((r: any) => r.uuid !== recipient.uuid)
    //   );
    // } else {
    //   setSelectedRecipients((s: any) => [...s, recipient]);
    // }
  };

  const onChangeComment = (e: any) => {
    setComment(e.target.value);
  };

  const validateAskMEC = () => {
    let validationErrors = [];
    if (selectedRecipients.length == 0) {
      validationErrors.push("Veuillez sélectionner au moins un destinataire.");
    }
    if (!comment) {
      validationErrors.push("Veuillez saisir un commentaire.");
    }
    setErrors(validationErrors);
    return validationErrors.length === 0;
  };

  const onSubmit = async () => {
    if (validateAskMEC()) {
      await mecActions.onAskMEC(
        { selectedRecipients, comment },
        onAfterValidate
      );
    }
  };

  return (
    <Stack verticalAlign="space-between" style={{ height: "100%" }}>
      <Stack tokens={{ childrenGap: 15 }}>
        <MessageBar className="bg-info" styles={MessageBarStyles}>
          Sélectionnez les destinataires à notifier et décrivez la raison de
          cette demande de mise en conformité ainsi que les informations à
          modifier.
        </MessageBar>
        <Stack horizontalAlign="start" tokens={{ childrenGap: 5 }}>
          <ChoiceGroup
            onChange={(e: any, option: any) => {
              onCheckRecipient(option.recipient);
            }}
            options={mecRecipients.map((recipient: any) => ({
              key: recipient.uuid,
              text: tools.getFullname(recipient),
              recipient: recipient,

              onRenderField: (props, render) => {
                return (
                  <Stack
                    verticalAlign="center"
                    horizontal
                    tokens={{ childrenGap: 5 }}
                    style={{ width: "100%" }}
                  >
                    <div style={{ fontWeight: 500 }}>{render!(props)}</div>
                    <div>{recipient.email}</div>
                    {recipient.roles?.map((r: any) => {
                      return (
                        <Badge
                          key={r}
                          text={r}
                          color="#f1f1f1"
                          style={{
                            padding: "2px 5px",
                          }}
                          textStyle={{
                            fontSize: 13,
                            fontWeight: "400",
                            color: "#646464",
                          }}
                        />
                      );
                    })}
                    {createurUuid == recipient.uuid && (
                      <Badge
                        key={"createur"}
                        text={"Créateur de la candidature"}
                        color="#f1f1f1"
                        style={{
                          padding: "2px 5px",
                        }}
                        textStyle={{
                          fontSize: 13,
                          fontWeight: "400",
                          color: "#646464",
                        }}
                      />
                    )}
                  </Stack>
                );
              },
            }))}
            label="Destinataire :"
            required
          />

          {/* {isLoadingMECRecipients ? (
            <Loader />
          ) : (
            <Stack horizontal>
              <DefaultButton
                styles={commonButtonStyles.buttonLink}
                style={{ border: 0 }}
                onClick={() => setSelectedRecipients(mecRecipients)}
              >
                Tout sélectionner
              </DefaultButton>
              <DefaultButton
                styles={commonButtonStyles.buttonLink}
                style={{ border: 0 }}
                onClick={() => setSelectedRecipients([])}
              >
                Tout déselectionner
              </DefaultButton>
            </Stack>
          )} */}

          {/* {mecRecipients.map((recipient: any) => {
            const value = selectedRecipients.find(
              (r: any) => r.uuid == recipient.uuid
            )
              ? true
              : false;
            return (
              <Checkbox
                styles={checkboxStyle}
                checked={value}
                onChange={() => onCheckRecipient(recipient)}
                name={recipient.uuid}
                label={tools.getFullname(recipient)}
                onRenderLabel={(props: any) => {
                  console.log(recipient);
                  return (
                    <Stack
                      verticalAlign="center"
                      horizontal
                      tokens={{ childrenGap: 5 }}
                      style={{ width: "100%" }}
                    >
                      <div style={{ fontWeight: 500 }}>{props.label}</div>
                      <div>{recipient.email}</div>
                      {recipient.roles?.map((r: any) => {
                        console.log("r", r);
                        return (
                          <Badge
                            key={r}
                            text={r}
                            color="#f1f1f1"
                            style={{
                              padding: "2px 5px",
                            }}
                            textStyle={{
                              fontSize: 13,
                              fontWeight: "400",
                              color: "#646464",
                            }}
                          />
                        );
                      })}
                    </Stack>
                  );
                }}
              />
            );
          })} */}
          <TextField
            label="Commentaire :"
            multiline
            rows={3}
            value={comment}
            name="comment"
            required
            placeholder="Expliquez la nature des corrections à effectuer et la raison de cette demande de mise en conformité."
            onChange={onChangeComment}
            styles={{ root: { width: "100%" } }}
          />
        </Stack>
        {errors.length > 0 && (
          <MessageBar messageBarType={2} styles={MessageBarErrorStyles}>
            {errors.map((e: any) => (
              <div>{e}</div>
            ))}
          </MessageBar>
        )}
      </Stack>
      <Stack
        horizontal
        horizontalAlign="space-between"
        style={{ width: "100%", marginTop: 40 }}
      >
        <DefaultButton
          type="button"
          onClick={panelActions.closeModal}
          text="Annuler"
        />
        <LoadButton
          type="button"
          onClick={onSubmit}
          text="Confirmer"
          isLoading={isMECAsking || isLoadingMECRecipients}
        />
      </Stack>
    </Stack>
  );
};

export default DialogDemanderMEC;

import { DefaultButton, PrimaryButton, Stack } from "@fluentui/react";
import { GoAlert } from "react-icons/go";
import useCustomPanel from "../../../../Common/Overlays/CustomPanel/useCustomPanel";
import InfoMessage from "../../../../Common/SelectField/common/InfoMessage/InfoMessage";
import { StatutCandidature } from "../../../../hooks/candidature/useCandidatureWorkflow";
import DialogDemanderMEC from "./DialogDemanderMEC";
import useMiseEnConfirmite from "./useMiseEnConfirmite";
import useInfoProfilUser from "../../../../hooks/useInfoProfilUser";

const BoutonDemanderMEC = ({
  text = "Corriger cette étape",
  statut,
  statut_candidature,
  infoMessage = "",
  onAfterValidate = () => {},
  createurUuid,
}: any) => {
  const { isEditMode, mecActions, activeMecsAsked } = useMiseEnConfirmite();
  const { actions: panelActions } = useCustomPanel();
  const { isAnyKindOfAdmin } = useInfoProfilUser();

  const onClickAskMEC = () => {
    panelActions.openPanel({
      header: "Demander une mise en conformité",
      body: (
        <DialogDemanderMEC
          onAfterValidate={onAfterValidate}
          createurUuid={createurUuid}
        />
      ),
    });
  };

  if (
    [
      StatutCandidature.DECISION_JURY,
      StatutCandidature.RESULTATS_JURY,
    ].includes(statut_candidature)
  ) {
    return null;
  }

  return (
    <>
      {isAnyKindOfAdmin && (
        <Stack horizontal horizontalAlign="end" style={{ marginBottom: 10 }}>
          {activeMecsAsked.find((s: any) => s.candidature_statut === statut) ? (
            <InfoMessage
              infoMessage={
                <Stack horizontal horizontalAlign="center">
                  <GoAlert
                    style={{
                      marginRight: "5px",
                      color: "red",
                      fontSize: "14px",
                    }}
                  />
                  Une mise en conformité a été demandée sur cette étape.
                </Stack>
              }
            ></InfoMessage>
          ) : (
            <PrimaryButton onClick={onClickAskMEC}>
              Demander une mise en conformité pour cette étape
            </PrimaryButton>
          )}
        </Stack>
      )}
      <Stack horizontal horizontalAlign="end">
        {isEditMode && (
          <DefaultButton
            onClick={() => mecActions.onDeactivateMEC(onAfterValidate)}
          >
            Annuler les corrections
          </DefaultButton>
        )}
        {!isEditMode && (
          <PrimaryButton onClick={() => mecActions.onActivateMEC(statut)}>
            {text}
          </PrimaryButton>
        )}
      </Stack>
      {infoMessage && (
        <InfoMessage
          infoMessage={<p style={{ textAlign: "right" }}>{infoMessage}</p>}
        ></InfoMessage>
      )}
    </>
  );
};

export default BoutonDemanderMEC;
